import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegistrationState } from '../register/register.component';
import { AuthDialogComponent, AuthDialogState } from './auth-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthDialogService {
  constructor(private dialog: MatDialog) {
  }

  dialogRef: MatDialogRef<AuthDialogComponent>;

  public async open(dialogState: AuthDialogState, registrationState?: RegistrationState): Promise<void> {
    const chunk = await import(
      `./auth-dialog.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<AuthDialogComponent>;

    this.dialogRef = this.dialog.open(dialogComponent, {
      panelClass: 'auth-dialog',
      autoFocus: false,
      data: { dialogState, registrationState }
    });
  }

  public close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }


}
