import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekipa-prismic-slice-wrapper',
  templateUrl: './prismic-slice-wrapper.component.html'
})
export class PrismicSliceWrapperComponent {
  @Input() content: any;

}
