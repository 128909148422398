import { Injectable } from '@angular/core';
import { DELETE_APPROVAL_BY_PARTICIPATION_ID, GET_APPROVALS_BY_TEAM_ID, GET_APPROVAL_BY_PARTICIPATION_ID, INSERT_APPROVAL_BY_PARTICIPATION_ID, ParticipationType } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ParticipationService } from './participation.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(
    private apollo: Apollo,
    private participationService: ParticipationService
  ) { }

  get userApproval$(): Observable<any[]> {
    return this.participationService.currentParticipation$.pipe(
      switchMap(
        (currentParticipation) => {
          return this.apollo.subscribe({
            query: GET_APPROVAL_BY_PARTICIPATION_ID,
            variables: {
              participation_id: currentParticipation.id
            }
          }
          ).pipe(
            map(
              res => {
                if (res.data) {
                  return (res.data as any).participant_approval
                }
              }
            )
          );
        }
      )
    )
  }

  get teamApprovals$(): Observable<any[]> {
    return this.participationService.currentParticipation$.pipe(
      switchMap(
        (currentParticipation) => {
          if (currentParticipation.participation_type === ParticipationType.Team) {
            return this.apollo.subscribe({
              query: GET_APPROVALS_BY_TEAM_ID,
              variables: {
                team_id: currentParticipation.team_id
              }
            }
            ).pipe(
              map(
                res => {
                  if (res.data) {
                    return (res.data as any).participant_approval
                  };
                }
              )
            );
          } else {
            return EMPTY;
          }
        }
      )
    )
  }

  set() {
    return this.mutateApproval(INSERT_APPROVAL_BY_PARTICIPATION_ID);
  }

  unset() {
    return this.mutateApproval(DELETE_APPROVAL_BY_PARTICIPATION_ID);
  }

  private mutateApproval(operation: DocumentNode) {
    return this.participationService.currentParticipation$.pipe(
      switchMap((
        currentParticipation
      ) => {
        return this.apollo.mutate({
          mutation: operation,
          variables: {
            participation_id: currentParticipation.id
          }
        })
      }),
      take(1)
    )
  }


}
