import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Challenge } from '@ekipa/shared';
import { plainToClass } from 'class-transformer';
import { PipesModule } from '../../../pipes/pipes.module';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { LegacyChallengeCardModule } from '../../challenge-card/legacy-challenge-card/challenge-card.module';


@Component({
  selector: 'ekipa-prismic-challenges-slice',
  templateUrl: './prismic-challenges-slice.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, LegacyChallengeCardModule, PipesModule, TranslocoRootModule]
})
export class PrismicChallengesSliceComponent implements OnInit {
  challenges: Challenge[];
  @Input() content: {
    primary: {
      challenges_title: any[],
      challenges_subtitle: string,
      text_alignment: string,
      horizontal_padding: boolean
    },
    fields: { challenge: any }[]
  };

  ngOnInit(): void {
    this.challenges = this.content.fields.map(
      field => plainToClass(Challenge, field.challenge)
    );
  }

}
