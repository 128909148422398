import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import {
  CLOSE_TEAM, GET_PARTICIPATING_TEAM,
  GET_TEAM, GET_TEAM_BY_CHALLENGE_ID,
  ITeam,
  OPEN_TEAM, UPDATE_TEAM
} from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, EMPTY, combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { IProfile } from '../models/profile.model';
import { ChallengesService } from './challenges.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private readonly _team = new BehaviorSubject<ITeam>(null);

  readonly team$ = this._team.asObservable();
  refresh$ = new BehaviorSubject<boolean>(null);



  get team(): ITeam {
    return this._team.getValue();
  }

  constructor(
    private apollo: Apollo,
    private challengesService: ChallengesService,
    private profileService: ProfileService,
  ) {
    this.team$ = this.currentTeam$;
  }


  // TODO delete
  closeTeam(id: number): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: CLOSE_TEAM,
      variables: {
        id
      }
    });
  }

  openTeam(id: number): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: OPEN_TEAM,
      variables: {
        id
      }
    });
  }

  getTeamByChallengeId(challengeId: string) {
    return this.apollo.query(
      {
        query: GET_TEAM_BY_CHALLENGE_ID,
        variables: {
          challenge_id: challengeId
        }
      }).pipe(
        map(({ data, error }) => {
          if (data) {
            return (data as any).team;
          }
        })
      );
  }

  get currentTeam$(): Observable<ITeam | null> {
    return combineLatest([
      this.refresh$,
      this.challengesService.challengeId$,
      this.profileService.profile$
    ]).pipe(switchMap(([refresh, challengeId, profile]) => {
      if (challengeId && profile) {
        return this._loadTeamByChallengeAndProfileId(challengeId, profile.id).pipe(
          map(({ data }) => {
            if (data.team.length) {
              return data.team[0]
            };
          })
        )
      } else {
        return EMPTY;
      }
    })
    )
  }

  updateCurrentTeam(newName: string, data: any) {
    return this.currentTeam$.pipe(
      take(1),
      switchMap(
        (team) => {
          return this._updateTeam(team.id, newName, data)
        }
      ))
  }

  private _updateTeam(
    teamId: number,
    name: string,
    data: object
  ): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: UPDATE_TEAM,
      variables: {
        id: teamId,
        name,
        data
      }
    }).pipe(tap(_ => this.refresh$.next(true)));
  }


  private _loadTeamByChallengeAndProfileId(challengeId: string, profileId: number):
    Observable<FetchResult<{ team: ITeam[]; }, Record<string, any>, Record<string, any>>> {
    return this.apollo.subscribe(
      {
        query: GET_TEAM,
        variables: {
          profile_id: profileId,
          challenge_id: challengeId
        }
      });
  }

  getParticipatingTeams(): Observable<ITeam[]> {
    return this.challengesService.challengeId$.pipe(switchMap(
      (challengeId: string) => {
        return this.apollo.query(
          {
            query: GET_PARTICIPATING_TEAM,
            variables: {
              challengeId
            },
          })
          .pipe(map((data) => {
            return (data as any).data.team
          }
          ));
      }
    ))
  }

  isAllProfileComplete$ = this.team$.pipe(
    map(
      (team) => {
        console.log(team)
        if (team) {
          console.log(team)
          const atLeastOneIncomplete = team.team_profiles.filter(
            participant => {
              this.profileService.isProfileComplete(participant.profile as IProfile) === false
            })
          if (atLeastOneIncomplete.length === 0) {
            return true;
          }
          return false;
        }
        return false;
      }
    ))


}
