<section class="text-gray-600 body-font">
  <div class="container px-5 first:mx-auto">
    <div class="flex flex-col sm:flex-row flex-wrap -m-4 text-center justify-center items-center divide-y-2 sm:divide-y-0 sm:divide-x-2">
      <div class="p-4 sm:w-1/4 w-1/2" *ngFor="let entry of content.fields">
        <h2 *ngIf="!!entry.figure.length" class="title-font font-medium sm:text-3xl text-xl text-gray-900" >{{entry.figure[0]['text']}}</h2>
        <p *ngIf="!!entry.figure_title.length" class="font-medium leading-relaxed text-gray-600">{{entry.figure_title[0].text}}</p>
      </div>
    </div>
  </div>
</section>
