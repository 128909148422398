import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitizeHtml', pure: true })
export class SanitizeHtmlPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(value: string): any {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
}

