import { Component, Input } from '@angular/core';
import { ProgressState } from '@ekipa/common-ui';



@Component({
  selector: 'ekipa-progress-dot',
  template: `
        <li class="mb-4">
        <div class="flex items-center mb-1">
          <div class="flex" style="margin-left: 1px">
            <div class="absolute flex justify-center items-center">
              <div class="absolute transition duration-400 bg-gray-400 rounded-full h-3 w-3 z-10"
              [ngClass]="{'bg-primary': state === ProgressState.Complete || state === ProgressState.Ongoing }"
              >
              </div>
              <div *ngIf="state === ProgressState.Ongoing" class=" absolute transition duration-400 animate-fade-in inline-block bg-pink-200 rounded-full h-6 w-6 z-8">
              </div>
            </div>
          </div>
          <div class="flex-1 ml-8 max-w-md">
            <h1 class='font-bold text-lg text-gray-600'>
              {{ title }}
            </h1>
            <span class="font-medium text-gray-500 ">
              {{ description }}
            </span>
          </div>
        </div>
      </li>
  
  `
})
export class ProgressDotComponent {
  @Input() state: ProgressState;
  @Input() title: string;
  @Input() description: string;
  ProgressState = ProgressState;



}
