import { Injectable } from '@angular/core';
import { GET_PROGRAMS_OVERVIEW, GET_PROGRAM_BY_ID } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { map, shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(
    private apollo: Apollo
  ) { }

  getProgramsOverview() {
    return this.apollo.query(
      {
        query: GET_PROGRAMS_OVERVIEW,
        context: { clientName: 'prismic' },
        variables: { lang: 'en-us' }
      }
    ).pipe(take(1), map(({ data, error }) => {
      if (data && (data as any).allPrograms.edges.length) {
        return (data as any).allPrograms.edges;
      }
    })
    ).pipe(shareReplay(1))
  }

  getProgramByUid(uid: string) {
    return this.apollo.query({
      query: GET_PROGRAM_BY_ID,
      context: {
        clientName: 'prismic'
      },
      variables: { lang: 'en-us', uid },
      fetchPolicy: "cache-first",
    }).pipe(shareReplay(1), map(({ data, error }) => {
      if (data && (data as any).allPrograms.edges.length) {
        return (data as any).allPrograms.edges[0].node;
      }
    }))

  }
}
