import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { BASE_URL } from '../tokens/tokens';

@Injectable({ providedIn: 'root' })
export class SeoService {
  constructor(
    private metaService: Meta,
    private titleService: Title,
    @Inject(BASE_URL) private baseUrl: string,
  ) { }

  updateTitle(title?: string) {
    if (!title) {
      title = 'ekipa - Open Innovation Platform'
    }
    this.titleService.setTitle(title);
  }

  updateMetaTags(metaTags?: MetaDefinition[]) {
    if (!metaTags) {
      metaTags = [
        { property: 'title', content: 'Open Innovation Platform' },
        { property: 'description', content: 'Innovative processes and methods to connect startups, researchers and students with the business community.' },
        { property: 'og:title', content: 'ekipa - Open Innovation Platform' },
        { property: 'og:description', content: 'Innovative processes and methods to connect startups, researchers and students with the business community.' },
        { property: 'og:image', content: 'https://app.ekipa.de/assets/images/preview.png' },
        { property: 'og:url', content: this.baseUrl },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'ekipa' },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:url', content: this.baseUrl },
        { property: 'twitter:image', content: 'https://app.ekipa.de/assets/images/preview.png' },
        { property: 'twitter:title', content: 'Open Innovation Platform' },
        { property: 'twitter:description', content: 'Innovative processes and methods to connect startups, researchers and students with the business community.' }
      ]
    }

    metaTags.forEach(m => this.metaService.updateTag(m));
  }


}
