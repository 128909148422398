import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { IconsModule } from '../icons/icons.module';
import { AvatarComponent } from './avatar.component';
import { ProfileInfoTooltipComponent } from './profile-info-tooltip/profile-info-tooltip.component';


@NgModule({
  declarations: [
    AvatarComponent,
    ProfileInfoTooltipComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconsModule,
    TippyDirective
  ],
  exports: [AvatarComponent]
})
export class AvatarModule { }
