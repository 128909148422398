import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[logoResize]',
  standalone: true
})
export class LogoResizeDirective {

  SURFACE_AREA_IN_PX = 9600;
  constructor(private el: ElementRef) {
  }

  @HostListener('load', ['$event'])
  onPageLoad(event: Event) {
    let ratio, height, width;
    if (this.el.nativeElement.naturalWidth > this.el.nativeElement.naturalHeight) {
      ratio = this.el.nativeElement.naturalHeight / this.el.nativeElement.naturalWidth;
      height = Math.sqrt(this.SURFACE_AREA_IN_PX * ratio);
      width = this.SURFACE_AREA_IN_PX / height;
    }
    if (this.el.nativeElement.naturalWidth < this.el.nativeElement.naturalHeight) {
      ratio = this.el.nativeElement.naturalWidth / this.el.nativeElement.naturalHeight;
      width = Math.sqrt(this.SURFACE_AREA_IN_PX * ratio);
      height = this.SURFACE_AREA_IN_PX / width;
    }
    if (this.el.nativeElement.naturalWidth === this.el.nativeElement.naturalHeight) {
      width = height = Math.sqrt(this.SURFACE_AREA_IN_PX);
    }
    this.el.nativeElement.width = width;
    this.el.nativeElement.height = height;

  }

}
