import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ekipa-message-input',
  templateUrl: './message-input.component.html',
})
export class MessageInputComponent {
  message: string;
  @Output() send = new EventEmitter();

  onSend() {
    if (this.message.length) {
      this.send.emit(this.message);
      this.message = ''
    }
  }

}
