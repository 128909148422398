import { Inject, Injectable } from '@angular/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { AuthService } from '../services/auth.service';
import { CORE_GRAPHQL_URL } from '../tokens/tokens';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class HasuraLinkService {

  constructor(
    @Inject(CORE_GRAPHQL_URL) private coreGraphQL: string,
    private authService: AuthService,
    private graphqlService: GraphqlService,
  ) { }

  create() {
    return new GraphQLWsLink(
      createClient({
        url: this.coreGraphQL,
        connectionParams: async () => {
          if (this.authService.user) {
            const token = await this.authService.user.getIdToken(true);
            if (!token) return {};
            return { headers: { Authorization: `Bearer ${token}` } };
          }
        },
        on: {
          connected: (socket: WebSocket) => {
            this.graphqlService.initSocket(socket);
          },
          closed: (closeEvent: CloseEvent) => {
            if (closeEvent.code === 4400) {
              if (this.authService.user) {
                console.log('disconnected')
                this.authService.signOut();
              }
            }
          }
        },
      })
    )
  }
}
