import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';

export type ChallengeCard = {
  title: string;
  description: string;
  coverImageUrl: string;
  logoUrl: string;
  deadline: Date;
  uid: string;
}


@Component({
  selector: 'ekipa-challenge-card',
  standalone: true,
  imports: [CommonModule, PipesModule, MatIconModule, RouterModule],
  templateUrl: './challenge-card.component.html',
})
export class ChallengeCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() coverImageUrl: string;
  @Input() logoUrl: string;
  @Input() deadline?: string;
  @Input() challengeUid: string;

  isOngoing: boolean;

  ngOnInit(): void {
    this.isOngoing = new Date(this.deadline).getTime() > new Date().getTime()
  }

}
