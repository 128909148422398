<div class="bg-white px-6 py-4 shadow-sm rounded-md transition mb-2">
  <div class="flex flex-row justify-between items-center transition">
    <ng-container *ngIf="uploadTask">
      <ng-container >
        <div class="flex flex-row">
          <div class="h-6 w-6" >
            <mat-progress-spinner *ngIf="isUploading" [value]="uploadProgress" diameter="24"></mat-progress-spinner>
          </div>
          <span class="ml-2 text-gray-400">
            {{ uploadTask.file.name }}
            <ng-container  *ngIf="isUploading">
              - {{ (uploadProgress | number:'1.0-0')}}%
            </ng-container>
          </span>
        </div>
        <!-- <button class="ml-4 my-4 btn btn-primary" (click)="onCancelUpload()">Cancel</button> -->
        <ekipa-close-icon (click)="onCancelUpload()"></ekipa-close-icon>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!uploadTask && file">
      <div class="flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span class="ml-2">
          {{ file.name }}
        </span>
      </div>

      <!-- Action items -->
      <div class="flex flex-row items-center">
        <a
        class="hover:cursor-pointer hover:opacity-80"
        href="{{file.download_url}}"
        target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
        </a>
        <div *ngIf="isDeletable" class="hover:cursor-pointer hover:opacity-80" (click)="onDeleteByUuid()">
          <ekipa-close-icon></ekipa-close-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
