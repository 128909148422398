import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

@Pipe({ name: 'distanceInWords', pure: false })
export class DistanceInWordsPipe implements PipeTransform {
  locale: any;

  constructor(@Inject(LOCALE_ID) public browserLocale: string) {
    if (this.browserLocale.includes('de')) {
      this.locale = de;
    } else {
      this.locale = en;
    }
  }

  transform(value: any): string {

    let res;
    if (value) {
      const date = new Date(value);
      if (date) {
        if (isValidDate(date)) {
          res = formatDistanceToNow(date, {
            locale: this.locale,
            addSuffix: true
          });
        }

      }
    }
    return res ? res : value;
  }
}

function isValidDate(d: Date) {
  const time = d.getTime();
  return typeof (time) === 'number';
}
