import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from '../../../pipes/pipes.module';
import { EligibleRegionComponent } from './eligible-region/eligible-region.component';
import { LegacyChallengeCardComponent } from './challenge-card.component';


@NgModule({
  declarations: [
    LegacyChallengeCardComponent,
    EligibleRegionComponent
  ],
  imports: [
    TranslocoModule,
    PipesModule,
    CommonModule,
    RouterModule
  ],
  exports: [LegacyChallengeCardComponent]
})
export class LegacyChallengeCardModule {
}
