<div class="mr-2 contents" #rla="routerLinkActive" routerLinkActive [routerLink]="routeTo ? routeTo : null">
  <div
    class="flex items-center gap-2 cursor-pointer text-gray-600 hover:bg-gray-200 hover:rounded-lg rounded-t-lg py-2 px-4 my-2 text-sm md:text-base text-center"
    [ngClass]="{
    'border-b-2 text-gray-800 font-medium border-primary py-4 !my-0 hover:bg-transparent hover:rounded-none': isActive === undefined ? rla.isActive : isActive
  }">
    <ng-content select="[svg]"></ng-content>
    <div class="whitespace-nowrap block" [ngClass]="{
    'hidden md:block': hideTextOnMobile
  }">
      <ng-content></ng-content>
    </div>
  </div>
</div>