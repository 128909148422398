import { Injectable } from '@angular/core';
import { IdTokenResult, User } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloQueryResult } from '@apollo/client/core';
import { GET_PROFILES_BY_EMAIL_LIKE, GET_PROFILE_BY_EMAIL, UPDATE_PROFILE_BY_ID, UPSERT_PROFILE } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';

import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { IProfile, PersonalData, StartupData } from '../models/profile.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private apollo: Apollo,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) {

  }

  // private readonly _profile = new BehaviorSubject<IProfile>(null);

  // readonly profile$ = this._profile.asObservable().pipe(shareReplay(1));

  readonly profile$: Observable<IProfile> = this.authService.authState$.pipe(
    switchMap(
      (user: User) => {
        if (user) {
          return this.getUserProfileByEmail(user.email).pipe(
            map(
              ({ data, error, loading }: ApolloQueryResult<any>) => {
                const profileRes = (data as any).profile[0];
                if (!profileRes) {
                  console.log('profile not found')
                  this.authService.signOut();
                  return;
                }
                user.getIdTokenResult().then(
                  (idTokenResult: IdTokenResult) => {
                    const hasuraClaims = idTokenResult.claims['https://hasura.io/jwt/claims'];
                    if (hasuraClaims) {
                      const claimedProfileId = +hasuraClaims['x-hasura-profile-id'];
                      const claimedUserUid = hasuraClaims['x-hasura-user-id'];
                      if (
                        !claimedUserUid
                        || !claimedProfileId
                        || claimedUserUid !== user.uid
                        || claimedProfileId !== profileRes.id

                      ) {
                        console.log('claims not found');
                        this.authService.signOut()
                      }
                    } // Else trigger auth claims reload
                  }
                ).catch(err => console.log(err));
                return profileRes;
              }
            )
          )
        } else {
          return of({});
        };
      }),
    shareReplay(1)
  )


  getProfilesLike(input: string) {
    return this.apollo.query({
      query: GET_PROFILES_BY_EMAIL_LIKE,
      variables: {
        _ilike: `%${input}%`
      }
    }).pipe(
      map(
        ({ data, loading, error }) => {
          if (data) {
            return (data as any).profile;
          }
        }
      )
    )
  }

  /**
   * Convert profile response from GraphQL with underscore to local store format and save it
   * @param profileRes
   * @private
   */
  private setProfile(profileRes: any): void { //TODO remove this

    const profile: IProfile = {
      id: profileRes.id,
      type: profileRes.type,
      display_name: profileRes.display_name,
      photo_url: profileRes.photo_url,
      email: profileRes.email,
      requests: profileRes.requests,
      participations: profileRes.participations,
      data: profileRes.type ===
        'personal'
        ?
        (new PersonalData(
          profileRes.data.university,
          profileRes.data.faculties,
          profileRes.data.skills,
          profileRes.data.linkedIn,
          profileRes.data.tel,
          profileRes.data.innovatorType,
          profileRes.data.xing,
          profileRes.data.country,
          profileRes.data.employer,
          profileRes.data.birthYear,
        ))
        :
        (new StartupData(
          profileRes.data.industry,
          profileRes.data.website,
          profileRes.data.skills,
          profileRes.data.foundedIn,
          profileRes.data.description,
          profileRes.data.company_logo,
          profileRes.data.country,
          profileRes.data.instagram,
          profileRes.data.linkedin,
          profileRes.data.representant_name,
          profileRes.data.representant_position,

        ))
    };
    // this._profile.next(profile);
  }


  getUserProfileByEmail(email: string): Observable<ApolloQueryResult<unknown>> {
    return this.apollo.watchQuery(
      {
        query: GET_PROFILE_BY_EMAIL,
        variables: {
          _eq: email
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges;
  }

  updateUserProfile(profile: IProfile): void {
    this.apollo.mutate({
      mutation: UPDATE_PROFILE_BY_ID,
      variables: {
        id: profile.id,
        data: profile.data,
        display_name: profile.display_name,
        photo_url: profile.photo_url || '',
        type: profile.type
      }
    })
      .pipe(take(1)).subscribe(({ data, error, loading }: ApolloQueryResult<any>) => {
        if (error) {
          this.snackbar.open("Error: Profile could not be updated", null, { duration: 3000 })
        }
        const profileRes = data.update_profile_by_pk;
        if (profileRes) {
          this.snackbar.open("Your profile has been updated", null, { duration: 3000 })
          this.setProfile({ ...profileRes });
        }
      });

  }

  update(profile: IProfile) {
    return this.apollo.mutate({
      mutation: UPSERT_PROFILE,
      variables: {
        data: profile.data,
        display_name: profile.display_name,
        email: profile.email.toLowerCase(),
        photo_url: profile.photo_url || '',
        type: profile.type
      }
    }).pipe(
      map(
        ({ data, error, loading }: ApolloQueryResult<any>) => {
          const profileRes = data.insert_profile_one;
          return profileRes
        }
      ),
      take(1)
    )
  }

  isProfileComplete(profile: IProfile): boolean {
    let isComplete = false;
    if (profile.type === 'startup') {
      isComplete = true;
    }
    if (profile.type === 'personal') {
      isComplete = !!profile.display_name && !!profile.data.birth_year && !!profile.data.country;
    }
    return isComplete;
  }

}
