import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekipa-prismic-partner-logos-slice',
  templateUrl: './prismic-partner-logos-slice.component.html'
})
export class PrismicPartnerLogosSliceComponent {

  @Input() content: any;

  constructor() { }

}
