<div class="hidden md:flex items-center">
  <div class="w-full space-y-6 lg:w-1/2 ">
    <!-- <pre>
      {{ content | json}}
    </pre> -->

    <ng-container *ngFor="let item of content.fields; let index = index">
      <div class="md:flex md:items-center md:-ml-4 cursor-pointer" (click)="selectedItemIndex = index">
        <span class="inline-block h-12 w-12 p-2 bg md:mx-4 rounded-full bg-gray-500">
        </span>

        <div class="mt-4 md:mx-4 md:mt-0 text-gray-700">
            <div [class.text-primary]="index === selectedItemIndex" class="text-xl font-semibold duration-300 transition" [innerHTML]="item.interactive_section_title | richtextToHTML"></div>
        </div>
      </div>

    </ng-container>
  </div>
  <div class="flex lg:self-start lg:w-1/2 lg:justify-center">
    <div class="mt-3 prose" [innerHTML]="content.fields[selectedItemIndex].interactive_section_content | richtextToHTML"></div>
  </div>
</div>


<div class="relative md:hidden w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14 no-scrollbar">
  <div class="snap-center shrink-0">
    <div class="shrink-0 w-4 sm:w-48"></div>
  </div>
  <div class="snap-always snap-center shrink-0 first:pl-8 last:pr-8" *ngFor="let item of content.fields; let index = index">
    <div class="shrink-0 w-80 h-full object-cover rounded-lg shadow-xl bg-white">
      <div class="flex items-center flex-col pt-8">
        <span class="inline-block h-12 w-12 p-2 bg md:mx-4 rounded-full bg-gray-500"></span>
        <div class="text-xl font-semibold duration-300 transition" [innerHTML]="item.interactive_section_title | richtextToHTML"></div>
        <div class="mt-3 mx-4 prose text-center" [innerHTML]="content.fields[index].interactive_section_content | richtextToHTML"></div>
      </div>
    </div>
  </div>

  <div class="snap-center shrink-0">
    <div class="shrink-0 w-4 sm:w-48"></div>
  </div>
</div>

