import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


interface PrismicCTAButton {
  label: string;
  url: string;
  color: 'primary' | 'secondary' | 'outlined'
}

@Component({
  selector: 'ekipa-prismic-call-to-action',
  templateUrl: './prismic-call-to-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class PrismicCallToActionComponent implements OnInit {
  @Input() content: any;
  buttons: PrismicCTAButton[];

  constructor() { }

  ngOnInit(): void {

    this.buttons = this.content.fields.map(
      button => {
        if (!button.button_label || !button.button_link) return;
        return {
          label: button.button_label,
          url: button.button_link?.url,
          color: button.color
        }
      }
    );

    // remove undefined values
    this.buttons = this.buttons.filter(button => button);
  }

}
