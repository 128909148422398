<div class="min-h-[50vh] bg-center bg-no-repeat bg-cover grid" [style.background-image]="'url(' + content.primary.background.url + ')'">
  <div class="grid grid-cols-3">
    <div class="col-span-0 sm:col-span-1"></div>
    <div class="col-span-3 sm:col-span-2 bg-gray-100 prose lg:prose-base mx-auto w-full px-8 py-24 bg-opacity-40 backdrop-blur-lg"
    [ngStyle]="{
      'background-color':
        content.primary.teaser_text_background_color
          ? content.primary.teaser_text_background_color
          : 'rgba(244, 244, 247, 0.658)'
    }">
      <div [innerHTML]="content.primary.text | richtextToHTML"></div>
      <button
      (click)="scrollToChallenges()"
      class="mr-4 no-underline mb-2 bg-white flex rounded-3xl font-bold py-2 px-4 items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width=2>
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
        </svg>
        To Challenges
      </button>
      <ng-container *ngFor="let button of content.fields">
        <!-- {{ button | json}} -->
        <button
          (click)="visitLink(button.button_link?.url)"
          target="_blank"
          class="inline-flex mr-4 btn no-underline mb-2"
          [ngClass]="{
            'btn-primary': button.button_color === 'primary',
            'btn-secondary': button.button_color === 'secondary',
            'btn-outline-primary': button.button_color === 'outlined'
          }"
          >{{button.button_label}}</button>
      </ng-container>
    </div>
  </div>
</div>
