<!-- <pre>
  {{ content | json }}
</pre> -->


<div class="py-8 px-8 flex flex-wrap md:flex-nowrap border-2 border-b-4 border-gray-100 bg-white rounded-xl hover:bg-gray-50 my-8"  *ngFor="let item of content.fields">
  <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
    <span *ngIf="item.card_entry_title && item.card_entry_title[0].text" class="font-semibold title-font text-gray-700 mb-4">{{item.card_entry_title[0].text}}</span>
    <div *ngIf="item.card_entry_image as image">
      <img class="w-auto h-10 max-w-60 object-contain" src={{image.url}} alt={{image.alt}}>
    </div>
  </div>
  <div class="md:flex-grow">
    <div class="leading-relaxed prose" [innerHTML]="item.card_entry_content |richtextToHTML"></div>
  </div>
</div>
