<div class="rounded-full inline-flex justify-center items-center cursor-pointer hover:opacity-80"
  [style.background-color]="src ? null : backgroundColor" [tp]="ProfileInfoTooltipComponent" [tpIsEnabled]="showTooltip"
  [tpInteractive]="true" placement="right-end" [tpData]="{profileId}" *ngIf="name" [ngClass]="{
  'h-24 w-24': size === 'lg',
  'h-16 w-16': size === 'md',
  'h-10 w-10': size === 'sm'
}">
  <div *ngIf="!src" [ngClass]="{
    'text-5xl pt-[5px]': size === 'lg',
    'text-3xl pt-[4px]': size === 'md',
    'text-lg pt-[1px]': size === 'sm'
  }" class="leading-none text-white">
    {{initials}}
  </div>
  <img *ngIf="src" class="avatar rounded-full" [ngClass]="{
    'h-24 w-24': size === 'lg',
    'h-16 w-16': size === 'md',
    'h-10 w-10': size === 'sm'
  }" [src]="src">
</div>
