import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieSettingsItems } from '../../services/cookie.service';

@Component({
  selector: 'ekipa-cookie-layer',
  templateUrl: './cookie-layer.component.html'
})
export class CookieLayerComponent {
  isAnalyticsAllowed = false;
  isCollapsed = true;
  @Input() values: CookieSettingsItems;

  constructor(private dialogRef: MatDialogRef<CookieLayerComponent>) {
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  rejectAll() {
    this.dialogRef.close({ functional: false, analytics: false });
  }

  acceptAll() {
    this.dialogRef.close({ functional: true, analytics: true });
  }

  saveSelection() {
    this.dialogRef.close({ analytics: this.isAnalyticsAllowed });
  }

}
