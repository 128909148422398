import { Injectable } from '@angular/core';
import { deleteObject, ref, Storage, uploadBytesResumable, UploadTask } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubmissionStoreService } from './submission-store.service';



@Injectable({
  providedIn: 'root'
})
export class UploadApi {
  constructor(
    private storage: Storage,
    public snackBar: MatSnackBar,
    public submissionStore: SubmissionStoreService,
  ) { }

  uploadFile(file: File, targetID: string, isStartup?: boolean): { task: UploadTask, fileID: string, filePath: string } {
    const fileID = appendToFilename(file.name, new Date().getTime());
    const filePath = isStartup ? `/startup/${targetID}/${fileID}` : `/team/${targetID}/${fileID}`;
    const fileRef = ref(this.storage, filePath);

    const task: UploadTask = uploadBytesResumable(fileRef, file)
    return {
      task,
      fileID,
      filePath
    };
  }

  upload(file: File, filePath: string): UploadTask {
    const fileRef = ref(this.storage, filePath)
    const task: UploadTask = uploadBytesResumable(fileRef, file);
    return task;
  }

  deleteFile(teamID, fileID): void {
    const fileRef = ref(this.storage, `/team/${teamID}/${fileID}`)
    deleteObject(fileRef)
  }
}

function appendToFilename(filename: string, timestamp: number): string {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1) {
    return filename + timestamp;
  } else {
    return (
      filename.substring(0, dotIndex) +
      '_' +
      timestamp +
      filename.substring(dotIndex)
    );
  }
}
