import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgModule } from '@angular/core';
import { environment } from '@ekipa/shared';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, Translation, TranslocoLoader, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { Observable } from 'rxjs/internal/Observable';
import { BASE_URL, IS_PRODUCTION } from '../tokens/tokens';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
  ) { }

  getTranslation(lang: string): Observable<any> {
    // manual cache-busting by changing file name to force reload of translations
    // TBD implement proper cache-busting
    // https://netbasal.com/strategies-for-cache-busting-translation-files-in-angular-86143ee14c3c
    return this.http.get<Translation>(`${this.baseUrl}/assets/i18n/${lang}-04072023.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        fallbackLang: 'de',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production, //TODO refactor to use @Inject value instead
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {
  constructor(@Inject(IS_PRODUCTION) private isProduction: boolean,) { }
}
