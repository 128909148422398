import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TippyService } from '@ngneat/helipopper';
import { ProfileInfoTooltipComponent } from './profile-info-tooltip/profile-info-tooltip.component';

@Component({
  selector: 'ekipa-avatar',
  templateUrl: './avatar.component.html',
  styles: [`
  :host {
    @apply contents;
  }
  `]
})
export class AvatarComponent implements OnChanges {
  @Input() name?: string;
  @Input() src?: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() showTooltip = true;
  @Input() profileId?: number;
  initials?: string;
  backgroundColor?: string;
  ProfileInfoTooltipComponent = ProfileInfoTooltipComponent;

  constructor(private tippy: TippyService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateAvatarWithInitials();
  }

  private generateAvatarWithInitials() {
    if (this.name) {
      this.initials = this.getInitials(this.name, 2)
      this.backgroundColor = this.colorFromString(this.initials)
    }
  }

  /**
   * Returns the initial letters of a name in a string.
   */
  private getInitials(name: string, size: number): string {
    name = name.trim();

    if (!name) {
      return '';
    }

    const initials = name.split(' ');

    if (size && size < initials.length) {
      return this.constructInitials(initials.slice(0, size));
    } else {
      return this.constructInitials(initials);
    }
  }

  /**
   * Iterates a person's name string to get the initials of each word in uppercase.
   */
  private constructInitials(elements: string[]): string {
    if (!elements || !elements.length) {
      return '';
    }

    return elements
      .filter(element => element && element.length > 0)
      .map(element => element[0].toUpperCase())
      .join('');
  }

  private colorFromString(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }
}

