import { composeSerializers, serialize, Element, wrapMapSerializer } from "@prismicio/richtext";
import { serializeSpan, serializeStandardTag, serializeHyperlink, serializeEmbed, serializeImage, serializePreFormatted } from "./lib/serializerHelpers.js";
const createDefaultHTMLSerializer = linkResolver => {
  return (_type, node, text, children, _key) => {
    switch (node.type) {
      case Element.heading1:
        return serializeStandardTag("h1", node, children);
      case Element.heading2:
        return serializeStandardTag("h2", node, children);
      case Element.heading3:
        return serializeStandardTag("h3", node, children);
      case Element.heading4:
        return serializeStandardTag("h4", node, children);
      case Element.heading5:
        return serializeStandardTag("h5", node, children);
      case Element.heading6:
        return serializeStandardTag("h6", node, children);
      case Element.paragraph:
        return serializeStandardTag("p", node, children);
      case Element.preformatted:
        return serializePreFormatted(node);
      case Element.strong:
        return serializeStandardTag("strong", node, children);
      case Element.em:
        return serializeStandardTag("em", node, children);
      case Element.listItem:
        return serializeStandardTag("li", node, children);
      case Element.oListItem:
        return serializeStandardTag("li", node, children);
      case Element.list:
        return serializeStandardTag("ul", node, children);
      case Element.oList:
        return serializeStandardTag("ol", node, children);
      case Element.image:
        return serializeImage(linkResolver, node);
      case Element.embed:
        return serializeEmbed(node);
      case Element.hyperlink:
        return serializeHyperlink(linkResolver, node, children);
      case Element.label:
        return serializeStandardTag("span", node, children);
      case Element.span:
      default:
        return serializeSpan(text);
    }
  };
};
const wrapMapSerializerWithStringChildren = mapSerializer => {
  const modifiedMapSerializer = {};
  for (const tag in mapSerializer) {
    const tagSerializer = mapSerializer[tag];
    if (tagSerializer) {
      modifiedMapSerializer[tag] = payload => {
        return tagSerializer({
          ...payload,
          // @ts-expect-error - merging blockSerializer types causes TS to bail to a never type
          children: payload.children.join("")
        });
      };
    }
  }
  return wrapMapSerializer(modifiedMapSerializer);
};
const asHTML = (richTextField, linkResolver, htmlSerializer) => {
  if (richTextField) {
    let serializer;
    if (htmlSerializer) {
      serializer = composeSerializers(typeof htmlSerializer === "object" ? wrapMapSerializerWithStringChildren(htmlSerializer) : (type, node, text, children, key) => htmlSerializer(type, node, text, children.join(""), key), createDefaultHTMLSerializer(linkResolver));
    } else {
      serializer = createDefaultHTMLSerializer(linkResolver);
    }
    return serialize(richTextField, serializer).join("");
  } else {
    return null;
  }
};
export { asHTML };
