import { JsonPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';

export interface FaqContent {
  title: string;
  text: any;
}

@Component({
  selector: 'ekipa-faq-entry',
  templateUrl: './faq-entry.component.html',
  imports: [PipesModule, NgIf, JsonPipe],
  standalone: true,
  styles: [
    `
        :host {
            @apply flex flex-col items-center;
        }
    `,
  ],
})
export class FaqEntryComponent implements OnInit {
  isOpen = false;
  @Input() content: FaqContent;

  constructor() { }

  ngOnInit(): void {
  }

}
