import { Injectable } from '@angular/core';
import { GET_FAQS } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FaqService {
  constructor(private apollo: Apollo) { }

  getFaq() {
    return this.apollo.query({
      query: GET_FAQS,
      context: { clientName: 'prismic' }
    }).pipe(map(({ data, error }) => {
      console.log(data);
      if (data && (data as any).allPlatform_faqs.edges.length) {
        return (data as any).allPlatform_faqs.edges;
      }
    }
    ))
  }


}
