import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'ekipa-editor',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './editor.component.html',
})
export class EditorComponent implements OnInit {
  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  holderId: string;
  @Input() set id(id: string) {
    console.log(id)
    this.holderId = `editor-js-${id}`;
  }
  editor: any;
  @HostListener('click', ['$event']) onClick(e) {
    console.log('click', e)
  }

  ngOnInit(): void {
    if (this.isBrowser) {

      // Dynamic module import to prevent window not defined error in SSR
      import('@editorjs/editorjs').then((m) => {
        this.editor = new m.default({
          holder: this.holderId,
        });
      })
    }
  }

}
