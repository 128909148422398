import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  socket: WebSocket
  isSocketClosed: boolean;

  initSocket(socket: WebSocket) {
    this.socket = socket;
    this.isSocketClosed = false;
  }

  reset() {
    if (this.socket.readyState === WebSocket.OPEN) {
      console.log('RESET SOCKET')
      this.socket.close(4205, 'Client Restart');
      this.isSocketClosed = true;
    }
  }

}


