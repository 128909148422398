import { Component, Input } from '@angular/core';
import { MessageMinFields } from '@ekipa/shared';

@Component({
  selector: 'ekipa-text-message',
  templateUrl: './text-message.component.html'
})
export class TextMessageComponent {
  @Input() message: MessageMinFields;
  @Input() sender: {
    displayName: string,
    photoUrl?: string,
    profileId?: number
  };
  @Input() direction: 'incoming' | 'outgoing' = 'incoming'


}
