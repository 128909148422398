import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import {
  ADD_REQUEST_MESSAGE, DELETE_REQUEST_BY_ID,
  GET_REQUESTS,
  GET_REQUESTS_BY_PROFILE,
  GET_REQUESTS_WITH_PROFILE_CHALLENGE,
  INSERT_REQUEST,
  INSERT_REQUEST_WITH_MESSAGE,
  ITeam,
  RESPONSE_TO_REQUEST
} from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, take } from 'rxjs/operators';
import { MessageInRequest, TeamJoinRequest } from '../models/team-join.model';
import { ProfileService } from './profile.service';
import { TeamService } from './team.service';


@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  private teamId: number;
  private challengeId: string;
  private profileId: number;

  private readonly _requests = new BehaviorSubject<TeamJoinRequest[]>(null);
  readonly requests$ = this._requests.asObservable();

  readonly currentTeamRequests$: Observable<TeamJoinRequest[]>; // TODO update this to Observable<TeamJoinRequest[]>

  get requests(): TeamJoinRequest[] {
    return this._requests.getValue();
  }

  constructor(
    private apollo: Apollo,
    private teamService: TeamService,
    private profileService: ProfileService
  ) {
    this.currentTeamRequests$ = this.teamService.currentTeam$.pipe(
      switchMap((team: ITeam) => {
        return this._loadTeamRequestsByTeamId(team.id).pipe(
          map(
            ({ data }) => { return data.request as TeamJoinRequest[] }
          )
        )
      })
    )
  }

  get currentOutgoingRequests$() {
    return this.profileService.profile$.pipe(
      take(1),
      switchMap(
        (profile) => {
          return this.apollo.subscribe(
            {
              query: GET_REQUESTS_BY_PROFILE,
              variables: {
                profile_id: profile.id
              }
            });
        }
      ),
      map(
        ({ data, errors }) => {
          if (data) {
            return (data as any).request
          }
        }
      )
    )
  }

  get currentOutgoingRequestTeamIds$() {
    return this.currentOutgoingRequests$.pipe(
      map(
        (requests) => {
          return requests.map((request) => request.team.id)
        }
      )
    )
  }



  setCurrentProfileChallenge(profileId: number, challengeId: string): void {
    this.profileId = profileId;
    this.challengeId = challengeId;

    this._loadTeamRequestsWithProfileChallenge(profileId, challengeId)
      .subscribe(({ data, error, loading }: ApolloQueryResult<any>) => {
        const requests = this._formatRequestResult(data);
        if (requests) {
          this._requests.next(requests);
        } else {
          // this.router.navigateByUrl('challenges');
        }
      });
  }

  refresh(): void {
    if (this.teamId) {
      this._refreshTeamRequests();
    } else {
      this._refreshChallengeRequests();
    }
  }

  createRequest(
    teamId: number,
    message?: string,
  ): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.profileService.profile$.pipe(
      take(1),
      switchMap(
        (profile) => {
          const variables = {
            team_id: teamId,
            profile_id: profile.id
          }
          message ? variables['message'] = message : null
          console.log(variables)
          if (profile) {
            return this.apollo.mutate({
              mutation: message ? INSERT_REQUEST_WITH_MESSAGE : INSERT_REQUEST,
              variables
            });
          }
        }
      )
    )
  }

  delete(
    id: number
  ): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: DELETE_REQUEST_BY_ID,
      variables: {
        id
      }
    });
  }

  addTeamMessage(requestId: number, message: MessageInRequest): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: ADD_REQUEST_MESSAGE,
      variables: {
        id: requestId,
        message
      }
    });
  }

  response(requestId: number, status: "accepted" | "rejected") {
    return this.apollo.mutate({
      mutation: RESPONSE_TO_REQUEST,
      variables: {
        status,
        requestId,
      }
    })
  }

  // acceptTeamRequest(requestId: number): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
  //   return this.apollo.mutate({
  //     mutation: SET_REQUEST_ACCEPTED,
  //     variables: {
  //       id: requestId
  //     }
  //   });

  // }

  private _refreshTeamRequests(): void {
    // this._loadTeamRequestsByTeamId(this.teamId)
    //   .subscribe(({ data, error, loading }: ApolloQueryResult<any>) => {
    //     const requests = this._formatRequestResult(data);
    //     if (requests) {
    //       this._requests.next(requests);
    //     } else {
    //       // this.router.navigateByUrl('challenges');
    //     }
    //   });
  }

  private _refreshChallengeRequests(): void {
    this._loadTeamRequestsWithProfileChallenge(this.profileId, this.challengeId)
      .subscribe(({ data }) => {
        const requests = this._formatRequestResult(data);

        if (requests) {
          this._requests.next(requests);
        } else {
          // this.router.navigateByUrl('challenges');
        }
      });
  }

  private _loadTeamRequestsByTeamId(teamId: number):
    Observable<FetchResult<{ request: TeamJoinRequest[]; }, Record<string, any>, Record<string, any>>> {
    return this.apollo.subscribe(
      {
        query: GET_REQUESTS,
        variables: {
          team_id: teamId
        }
      });
  }

  private _loadTeamRequestsWithProfileChallenge(profileId: number, challengeId: string):
    Observable<FetchResult<{ request: TeamJoinRequest[]; }, Record<string, any>, Record<string, any>>> {
    return this.apollo.subscribe(
      {
        query: GET_REQUESTS_WITH_PROFILE_CHALLENGE,
        variables: {
          profileId,
          challengeId
        }
      });
  }

  private _formatRequestResult(data: any): TeamJoinRequest[] {
    const res: any[] = (data as any).request;
    const requests = res.map(r => {
      return {
        id: r.id,
        teamId: r.team_id,
        profileId: r.profile_id,
        status: r.status,
        messages: r.messages,
        team: r.team
      };
    });
    return requests;
  }

}
