import { escapeHTML } from "./escapeHTML.js";
import { RichTextNodeType, LinkType } from "@prismicio/types";
import { asLink } from "../asLink.js";
const getLabel = node => {
  return "data" in node && "label" in node.data ? ` class="${node.data.label}"` : "";
};
const serializeStandardTag = (tag, node, children) => {
  return `<${tag}${getLabel(node)}>${children.join("")}</${tag}>`;
};
const serializePreFormatted = node => {
  return `<pre${getLabel(node)}>${escapeHTML(node.text)}</pre>`;
};
const serializeImage = (linkResolver, node) => {
  let imageTag = `<img src="${node.url}" alt="${escapeHTML(node.alt)}"${node.copyright ? ` copyright="${escapeHTML(node.copyright)}"` : ""} />`;
  if (node.linkTo) {
    imageTag = serializeHyperlink(linkResolver, {
      type: RichTextNodeType.hyperlink,
      data: node.linkTo,
      start: 0,
      end: 0
    }, [imageTag]);
  }
  return `<p class="block-img">${imageTag}</p>`;
};
const serializeEmbed = node => {
  return `<div data-oembed="${node.oembed.embed_url}" data-oembed-type="${node.oembed.type}" data-oembed-provider="${node.oembed.provider_name}"${getLabel(node)}>${node.oembed.html}</div>`;
};
const serializeHyperlink = (linkResolver, node, children) => {
  switch (node.data.link_type) {
    case LinkType.Web:
      {
        return `<a href="${escapeHTML(node.data.url)}" ${node.data.target ? `target="${node.data.target}" ` : ""}rel="noopener noreferrer"${getLabel(node)}>${children.join("")}</a>`;
      }
    case LinkType.Document:
      {
        return `<a href="${asLink(node.data, linkResolver)}"${getLabel(node)}>${children.join("")}</a>`;
      }
    case LinkType.Media:
      {
        return `<a href="${node.data.url}"${getLabel(node)}>${children.join("")}</a>`;
      }
  }
};
const serializeSpan = content => {
  return content ? escapeHTML(content).replace(/\n/g, "<br />") : "";
};
export { getLabel, serializeEmbed, serializeHyperlink, serializeImage, serializePreFormatted, serializeSpan, serializeStandardTag };
