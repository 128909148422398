import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CookieModule as NGXCookieModule } from 'ngx-cookie';
import { CookieLayerComponent } from './components/cookie-layer/cookie-layer.component';
import { CookieService } from './services/cookie.service';

@NgModule({
  declarations: [CookieLayerComponent],
  imports: [
    CommonModule,
    NGXCookieModule,
    MatIconModule,
    MatCheckboxModule,
    CdkAccordionModule
  ],
  providers: [CookieService]
})
export class CookieModule { }
