import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { API_URL, IS_PRODUCTION } from '../tokens/tokens';
;

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  constructor(
    @Inject(API_URL) private apiURL: string,
    @Inject(IS_PRODUCTION) private isProduction: string,
    private http: HttpClient,
  ) { }

  resetDB() {
    if (!this.isProduction) {
      return this.http.post(`${this.apiURL}db/reset`, {}).pipe(catchError(err => err))
    }
  }

  wipeDB() {
    if (!this.isProduction) {
      return this.http.post(`${this.apiURL}db/wipe`, {}).pipe(catchError(err => err))
    }
  }


}
