import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickableListItemComponent } from './clickable-list-item.component';



@NgModule({
  declarations: [ClickableListItemComponent],
  imports: [
    CommonModule
  ],
  exports: [ClickableListItemComponent]
})
export class ClickableListItemModule { }
