<a routerLink="/challenges/{{challenge.uid}}"
  class="shadow-md rounded-3xl md:h-full border-8 border-white grid grid-rows-3 sm:auto-rows-fr pb-6 sm:pb-0 group cursor-pointer"
  *ngIf="challenge">
  <div [style.background-image]="'url(' + challenge.cardCoverURL + ')'"
    [ngClass]="{' 2xl:row-span-2 2xl:rounded-t-2xl 2xl:rounded-b-none': isExtended  }"
    class="bg-white rounded-t-2xl rounded-bl-none bg-no-repeat bg-cover bg-center row-span-2 sm:row-span-1">
  </div>
  <div class="px-6 pt-6 pb-2 h-full flex flex-col justify-between"
    [ngClass]="{'bg-black ': challenge.uid.includes('deloitte')} ">
    <div>
      <img *ngIf="challenge.logoURL && !challenge.uid.includes('deloitte')" class="h-6 sm:h-12"
        src="{{ challenge.logoURL }}" alt="{{challenge.uid}}'s provider logo" />

      <!-- TODO move this logic to challenge config -->
      <img *ngIf="challenge.uid.includes('deloitte')" class="h-6 sm:h-12 -ml-1"
        src="https://images.prismic.io/ekipa/ZfFahUmNsf2sHiYQ_Delloite_wei%C3%9Ffreigestellt_RGB.png?auto=format,compress"
        alt="{{challenge.uid}}'s provider logo" />

    </div>
  </div>
  <div [ngClass]="{' rounded-tr-none ': isExtended} "
    class="px-6 rounded-bl-xl rounded-br-xl row-span-3 h-full flex flex-col justify-start gap-4 mt-4">

    <h3 class="font-medium sm:text-lg text-gray-700 sm:font-semibold group-hover:underline underline-offset-1">
      {{ challenge.title ? challenge.title[0].text : 'Unnamed challenge' }}
    </h3>

    <div class="hidden sm:block" [innerHTML]="challenge.teaser | richtextToHTML"></div>
    <div class="mb-4 mt-3 " *ngIf="challenge.is_completed">
      <div class="deadline mb-1 has-text-weight-bold">
        <b> ✅ {{ "teaserTitles.completed" | transloco }} </b>
      </div>
      <span *ngIf="challenge.winners" [innerHTML]="challenge.winners | richtextToHTML"></span>
      <br />
    </div>
    <ng-content></ng-content>
  </div>

  <!-- <div class="hidden bg-white row-span-1 col-span-3 overflow-hidden timeline pt-6 font-sm text-sm font-semibold"
    [ngClass]="{'sm:flex sm:items-center 2xl:col-span-7 ': showTimeline }">
    <ol>
      <li>Start</li>
      <li *ngIf="challenge.next_step_1">
        <span [innerHTML]="challenge.next_step_1[0].text"></span>
        <br />
        <p class="font-normal">{{ challenge.qualification | date }}</p>
      </li>
      <li *ngIf="challenge.next_step_2">
        <span [innerHTML]="challenge.next_step_2[0].text"></span>
        <br />
        <p class="font-normal ">{{ challenge.current_deadline | date }}</p>
      </li>
    </ol>
  </div> -->
</a>