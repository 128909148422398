<!-- <pre>
  {{
    content | json
  }}
</pre> -->

<section class="text-gray-600 body-font" [ngClass]="{'md:w-11/12 2xl:max-w-9/12 mx-auto px-4': content.primary.has_horizontal_padding}">
  <div
  [ngClass]="{
    'md:flex-row': content.primary.visual_position === 'right',
    'md:flex-row-reverse': content.primary.visual_position === 'left'
  }"
  class="mx-auto flex py-24 flex-col items-center justify-around">
    <div
    [ngClass]="{
      'items-start': content.primary.text_alignment === 'left',
      'items-end': content.primary.text_alignment === 'right',
      'items-center': content.primary.text_alignment === 'background'
    }"
    class="md:w-1/2 flex flex-col  mb-16 md:mb-0 text-center">
    <div
      [ngClass]="{
      'text-left': content.primary.text_alignment === 'left',
      'text-right': content.primary.text_alignment === 'right',
      'text-center': content.primary.text_alignment === 'center'
    }"
    class="content" [innerHTML]="content.primary.title | richtextToHTML"></div>
      <div
        class="mb-8 leading-relaxed content"  [innerHTML]="content.primary.paragraph | richtextToHTML"></div>
      <div class="flex justify-center" *ngIf="buttons.length">
        <ng-container *ngFor="let button of buttons">
          <a
            target="_blank"
            [href]="button.url"
            class="inline-flex mr-4 btn"
            [ngClass]="{
              'btn-primary': button.color === 'primary',
              'btn-secondary': button.color === 'secondary',
              'btn-outline-primary': button.color === 'outlined'
            }"
            >{{button.label}}</a>
        </ng-container>
      </div>
    </div>
    <div class="md:w-1/2 flex justify-center">
      <img class="object-cover object-center rounded"
        [alt]="content.primary.visual.alt"
        [height]="content.primary.visual.dimensions.height"
        [width]="content.primary.visual.dimensions.width"
        [src]="content.primary.visual.url"
      >
    </div>
  </div>
</section>
