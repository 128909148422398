import { LinkType } from "@prismicio/types";
import { documentToLinkField } from "./documentToLinkField.js";
const asLink = (linkFieldOrDocument, linkResolver) => {
  if (!linkFieldOrDocument) {
    return null;
  }
  const linkField =
  // prettier-ignore
  // @ts-expect-error - Bug in TypeScript 4.9: https://github.com/microsoft/TypeScript/issues/51501
  // TODO: Remove the `prettier-ignore` comment when this bug is fixed.
  "link_type" in linkFieldOrDocument ? linkFieldOrDocument : documentToLinkField(linkFieldOrDocument);
  switch (linkField.link_type) {
    case LinkType.Media:
    case LinkType.Web:
      return "url" in linkField ? linkField.url : null;
    case LinkType.Document:
      {
        if ("id" in linkField && linkResolver) {
          const resolvedURL = linkResolver(linkField);
          if (resolvedURL != null) {
            return resolvedURL;
          }
        }
        if ("url" in linkField && linkField.url) {
          return linkField.url;
        }
        return null;
      }
    case LinkType.Any:
    default:
      return null;
  }
};
export { asLink };
