import { Injectable } from '@angular/core';
import { CreateTeam } from '@ekipa/api-interfaces';
import { CREATE_TEAM_AND_PARTICIPATE, GET_PARTICIPATIONS_BY_TIME_RANGE, GET_USER_PARTICIPATIONS, GET_USER_PARTICIPATION_FOR_DASHBOARD, GET_USER_PARTICIPATION_WITH_CHALLENGE_ID, LEAVE_CHALLENGE, PARTICIPATE, ParticipationMinFields, ParticipationType } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, EMPTY, combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ChallengesService } from './challenges.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {
  refresh$ = new BehaviorSubject<boolean>(null);
  currentParticipation$ = combineLatest([
    this.refresh$,
    this.profileService.profile$,
    this.challengesService.currentChallenge$
  ]).pipe(
    switchMap(
      ([refresh, profile, currentChallenge]) => {
        if (profile && currentChallenge) {
          return this._getUserParticipationWithChallengeId(profile.id, currentChallenge.uid)
        } else {
          return EMPTY;
        }
      }
    ),
    distinctUntilChanged(),
    shareReplay(1),
  )

  constructor(
    private apollo: Apollo,
    private profileService: ProfileService,
    private challengesService: ChallengesService
  ) { }


  getAllParticipations(): Observable<ParticipationMinFields[]> {
    return combineLatest([
      this.refresh$,
      this.profileService.profile$
    ]).pipe(
      switchMap(
        ([refresh, profile]) => {
          if (profile) {
            return this._getAllUserParticipationsByProfileId(profile.id, GET_USER_PARTICIPATIONS)
          } else { return EMPTY }
        }
      ),
      distinctUntilChanged()
    )
  }

  getDetailedParticipations(): Observable<ParticipationMinFields[]> {
    return combineLatest([
      this.refresh$,
      this.profileService.profile$
    ]).pipe(
      switchMap(
        ([refresh, profile]) => {
          if (profile) {
            return this._getAllUserParticipationsByProfileId(profile.id, GET_USER_PARTICIPATION_FOR_DASHBOARD)
          } else { return EMPTY }
        }
      ),
      distinctUntilChanged()
    )
  }

  participate(participationType: ParticipationType) {
    return this.challengesService.currentChallenge$.pipe(
      take(1),
      switchMap(
        (challenge) => {
          return this.apollo.mutate({
            mutation: PARTICIPATE,
            variables: {
              participationType,
              challengeId: challenge.uid
            }
          })
        }
      )
    )
  }

  createTeamAndParticipate(team: Partial<CreateTeam>) {
    return this.challengesService.currentChallenge$.pipe(
      take(1),
      switchMap(
        (challenge) => {
          return this.apollo.mutate({
            mutation: CREATE_TEAM_AND_PARTICIPATE,
            variables: {
              challengeId: challenge.uid,
              teamInput: team.formInput
            }
          })
        }
      )
    )
  }

  leaveChallenge() {
    return this.challengesService.currentChallenge$.pipe(
      take(1),
      switchMap(
        (challenge) => {
          return this.apollo.mutate({
            mutation: LEAVE_CHALLENGE,
            variables: {
              challengeId: challenge.uid
            }
          })
        }
      )
    )
  }

  getParticipationsByDateRange(startDate: Date, endDate?: Date) {
    endDate = endDate || new Date();

    // convert startdate to a string with the format YYYY-MM-DD
    const start = startDate.toISOString().split('T')[0];
    const end = endDate.toISOString().split('T')[0];

    return this.profileService.profile$.pipe(
      // wait for profile to load before querying
      // TBD make query wait for createApolloLink
      take(1),
      switchMap(
        (profile) => {
          return this.apollo.query({
            query: GET_PARTICIPATIONS_BY_TIME_RANGE,
            fetchPolicy: 'no-cache',
            variables: {
              start,
              end
            }
          }).pipe(map(({ data, errors }) => {
            return (data as any).participation;
          }))
        }
      )
    )

  }


  private _getAllUserParticipationsByProfileId(profileId: number, query) {
    return this.apollo.query({
      query,
      variables: {
        profile_id: profileId
      },
      fetchPolicy: 'network-only'
    }).pipe(map(({ data }) => {
      return (data as any).participation;
    }))
  }


  private _getUserParticipationWithChallengeId(
    profileId: number,
    challengeId: string
  ): Observable<ParticipationMinFields> {
    return this.apollo.subscribe({
      query: GET_USER_PARTICIPATION_WITH_CHALLENGE_ID,
      variables: {
        challenge_id: challengeId,
        profile_id: profileId
      },
      fetchPolicy: 'network-only'
    }).pipe(map(({ data }) => {
      return (data as any).participation[0];
    }), catchError((err) => {
      return EMPTY;
    }))
  }
}
