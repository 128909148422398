<!-- <pre>
  {{
    content | json
  }}
</pre> -->

<ng-container [ngSwitch]="content.__typename">
  <div class="pb-24" *ngSwitchCase="'ProgramBodyCustomer_logos'">
    <ekipa-prismic-partner-logos-slice [content]='content'></ekipa-prismic-partner-logos-slice>
  </div>
  <div class="pb-24" *ngSwitchCase="'ProgramBodyChallenge'">
    <ekipa-prismic-challenges-slice [content]='content'></ekipa-prismic-challenges-slice>
  </div>
  <div class="pb-24" *ngSwitchCase="'ProgramBodyCall_to_action'">
    <ekipa-prismic-call-to-action [content]='content'></ekipa-prismic-call-to-action>
  </div>
  <div class="pb-24" *ngSwitchCase="'ProgramBodyFaq_section'">
    <ekipa-prismic-faq-slice [content]='content'></ekipa-prismic-faq-slice>
  </div>
  <div class="pb-24" *ngSwitchCase="'ProgramBodyTeaser'">
    <ekipa-prismic-teaser [content]='content'></ekipa-prismic-teaser>
  </div>
  <div class="pb-24" *ngSwitchCase="'ProgramBodyKey_figures'">
    <ekipa-prismic-key-figures [content]='content'></ekipa-prismic-key-figures>
  </div>
  <div class="pb-24" *ngSwitchCase="'Ongoing_challenges_pageBodyChallenge'">
    <ekipa-prismic-challenges-slice [content]='content'></ekipa-prismic-challenges-slice>
  </div>
</ng-container>
<ng-container [ngSwitch]="content.type">
  <div class="pb-24" *ngSwitchCase="'interactive_section'">
    <ekipa-prismic-interactive-section-slice [content]='content'></ekipa-prismic-interactive-section-slice>
  </div>
  <div class="pb-24" *ngSwitchCase="'card_list'">
    <ekipa-prismic-card-slice [content]='content'></ekipa-prismic-card-slice>
  </div>
  <div class="pb-24" *ngSwitchCase="'content'">
    <ekipa-prismic-content-slice [content]='content'></ekipa-prismic-content-slice>
  </div>
</ng-container>
