import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ekipa-prismic-faq-slice',
  templateUrl: './prismic-faq-slice.component.html'
})
export class PrismicFaqSliceComponent implements OnInit {
  @Input() content: any;

  constructor() { }

  ngOnInit(): void {
  }

}
