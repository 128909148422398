import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'ekipa-prismic-teaser',
  templateUrl: './prismic-teaser.component.html'
})
export class PrismicTeaserComponent {
  @Input() content: any;
  view: HTMLElement;

  constructor(@Inject(DOCUMENT) public document: Document) {

  }

  scrollToChallenges() {
    this.view = this.document.getElementById("programChallenges");
    console.log(this.view)
    this.view.scrollIntoView()
    // this.viewportScroller.scrollToAnchor('challenges')
  }

  visitLink(url: string) {
    location.href = url;
  }

}
