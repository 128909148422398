import { Injectable } from '@angular/core';
import { DELETE_FILE_BY_UUID, GET_FILES_BY_PARTICIPATION_ID, GET_FILES_BY_TEAM_ID, INSERT_FILE } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ParticipationService } from './participation.service';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  refresh$ = new BehaviorSubject<boolean>(null);

  constructor(
    private apollo: Apollo,
    private teamService: TeamService,
    private participationService: ParticipationService
  ) {

  }

  get currentUnsubmittedFiles$() {
    return combineLatest([
      this.refresh$,
      this.teamService.currentTeam$,
      this.participationService.currentParticipation$
    ]).pipe(
      switchMap(([refresh, currentTeam, currentParticipation]) => {
        const query = currentTeam
          ? GET_FILES_BY_TEAM_ID
          : GET_FILES_BY_PARTICIPATION_ID;
        const variables = currentTeam
          ? { team_id: currentTeam.id }
          : { participation_id: currentParticipation.id };
        return this.apollo.subscribe({
          query,
          variables,
          fetchPolicy: 'network-only'
        }).pipe(
          map(res => {
            if (res.data) {
              return (res.data as any).file
            } else return;

          }
          ),
          // tap(res => console.log(res))
        )
      }),
    )
  }

  insertUploadedFile(fileMetadata: {
    path: string,
    name: string,
    downloadUrl: string
  }) {
    return this.participationService.currentParticipation$.pipe(
      switchMap((currentParticipation) => {
        return this.apollo.mutate({
          mutation: INSERT_FILE,
          variables: {
            participation_id: currentParticipation.id,
            team_id: currentParticipation.team_id,
            path: fileMetadata.path,
            name: fileMetadata.name,
            download_url: fileMetadata.downloadUrl
          }
        })
      }),
      take(1),
      tap(() => this.refresh$.next(true)),
    )
  }

  removeByUuid(uuid: string) {
    return this.apollo.mutate({
      mutation: DELETE_FILE_BY_UUID,
      variables: { uuid }
    }).pipe(
      take(1),
      tap(() => this.refresh$.next(true)),
    )
  }
}
