import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class BurgerMenuService {

  private burgerMenuStateSubject$ = new BehaviorSubject<boolean>(false);
  currentState$ = this.burgerMenuStateSubject$.asObservable();

  toggle() {
    this.currentState$.pipe(
      take(1),
      map((state: boolean) => {
        this.burgerMenuStateSubject$.next(!state)
      })
    ).subscribe()

  }

}
