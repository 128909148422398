import { isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, InjectionToken, NgModule, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { Observable } from 'rxjs';
import { HasuraLinkService } from './hasura-link.service';
import { PrismicLinkService } from './prismic-link.service';


@Injectable()
export class PrismicGraphQLParamsInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest.url.includes('prismic')) {
      let params = httpRequest.params;
      if (httpRequest.params.get('operationName') === 'MyQuery') {
        // Shorten query by remove spaces to prevent 414
        const queryParam = params.get('query')
          .replace(/#.*\n/g, '')
          .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
          .replace(/:\s/g, ':')
          .replace(/,\s/g, ',')
          .replace(/\)\s\{/g, '){')
          .replace(/\}\s/g, '}')
          .replace(/\{\s/g, '{')
          .replace(/\s\}/g, '}')
          .replace(/\s\{/g, '{')
          .replace(/\)\s/g, ')')
          .replace(/\(\s/g, '(')
          .replace(/\s\)/g, ')')
          .replace(/\s\(/g, '(')
          .replace(/=\s/g, '=')
          .replace(/\s=/g, '=')
          .replace(/@\s/g, '@')
          .replace(/\s@/g, '@')
          .replace(/\s\$/g, '$')
          .replace(/\s\./g, '.')
          .trim()
        params = params.delete('query')
        params = params.set('query', queryParam);
      }
      if (params.get('operationName') === 'undefined') {
        params = params.delete('operationName')
      }
      return next.handle(httpRequest.clone({
        params: params,
      }))
    }
    return next.handle(httpRequest.clone());
  }
}




const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: loadPrismicRef, deps: [PrismicLinkService], multi: true },
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS, useFactory: loadApollo,
      deps: [PLATFORM_ID, PrismicLinkService, HasuraLinkService, APOLLO_CACHE, TransferState]
    },
    { provide: HTTP_INTERCEPTORS, useClass: PrismicGraphQLParamsInterceptor, multi: true },
  ]
})
export class GraphQLModule { }


function createApolloLink(
  platformId: string,
  prismicLinkService: PrismicLinkService,
  hasuraLinkService: HasuraLinkService
): ApolloLink {
  const prismicHttpLink = prismicLinkService.createPrismicLink();
  let apolloLink: ApolloLink;
  if (isPlatformBrowser(platformId)) {
    const hasuraWebsocketLink = hasuraLinkService.create();

    apolloLink = ApolloLink.split(
      operation => operation.getContext().clientName === 'prismic',
      prismicHttpLink,
      hasuraWebsocketLink
    )

  } else {
    apolloLink = ApolloLink.split(
      operation => operation.getContext().clientName === 'prismic',
      prismicHttpLink,
    );
  }
  return apolloLink;
}

function loadApollo(
  platformId: string,
  prismicLinkService: PrismicLinkService,
  hasuraLinkService: HasuraLinkService,
  cache: InMemoryCache,
  transferState: TransferState,
) {
  if (isPlatformBrowser(platformId)) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
    cache.reset();
  }
  return {
    link: createApolloLink(platformId, prismicLinkService, hasuraLinkService),
    cache,
  }

}


function loadPrismicRef(prismicLinkService: PrismicLinkService) {
  return () => prismicLinkService.loadPrismicRef();
}
