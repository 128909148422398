<div class="block overflow-hidden bg-white text-center w-20">
  <div class="bg-primary text-white py-1 text-sm">
      {{ date | date: "MMM"}}
  </div>
  <div class="pt-1 border-l border-r">
      <span class="text-2xl font-bold">{{ date | date: "d"}}</span>
  </div>
  <div class="pb-2 px-2 border-l border-r border-b rounded-b flex justify-between">
      <span class="text-xs font-bold">{{ date | date: "EEE"}}</span>
      <span class="text-xs font-bold">{{ date | date: "y"}}</span>
  </div>
</div>