import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Challenge } from '@ekipa/shared';


@Component({
  selector: 'ekipa-challenge-card',
  templateUrl: './challenge-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegacyChallengeCardComponent {

  @Input() isExtended = true;
  @Input({ required: true }) challenge: Challenge;
  @Input() showTimeline = false;


}

