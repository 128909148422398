import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import { AvatarModule } from '../avatar/avatar.module';
import { MessageInputComponent } from './message-input/message-input.component';
import { TextMessageComponent } from './text-message.component';



@NgModule({
  declarations: [
    TextMessageComponent,
    MessageInputComponent
  ],
  exports: [
    TextMessageComponent,
    MessageInputComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    PipesModule,
    FormsModule
  ]
})
export class TextMessageModule { }
