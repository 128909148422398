import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabItemComponent } from './tab-item/tab-item.component';



@NgModule({
  declarations: [
    TabItemComponent,
    TabGroupComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [TabItemComponent, TabGroupComponent]
})
export class TabModule { }
