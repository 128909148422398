import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';


export interface Step {
  symbol?: string;
  title: string;
  description: string;
}



@Component({
  selector: 'ekipa-form-step',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-step.component.html',
  styles: [`
   :host {
    display: list-item;
    @apply overflow-hidden relative flex-1 cursor-pointer;
   }
  `]
})
export class FormStepComponent {
  @Input() step: Step;
  @Input() index: number;
  @Input() isDone = false;
  @Input() isActive = false;
  @Input() isDisabled = false;
  @Output() stepClicked = new EventEmitter<number>();
  @HostListener('click', ['$event']) onClick(e) {
    if (this.isDisabled) {
      e.stopPropagation();
      e.preventDefault();
      return;
    };

    this.stepClicked.emit(this.index);
  }
}
