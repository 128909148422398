import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekipa-tab-item',
  templateUrl: './tab-item.component.html',
  styles: [`
  :host {
    @apply contents;
  }
  `]
})
export class TabItemComponent {

  @Input() routeTo: string;
  @Input() isActive: boolean;
  @Input() hideTextOnMobile = true;



}
