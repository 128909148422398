import { Injectable } from '@angular/core';
import { DELETE_TAG, GET_TAGS, INSERT_TAG, INSERT_TAG_PARTICIPATION_RELATIONS, REMOVE_TAG_FROM_PARTICIPATION, UPDATE_TAG } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';

@Injectable()
export class TagService {
  constructor(private apollo: Apollo) { }

  createTag(hexCode, name) {
    return this.apollo.mutate({
      mutation: INSERT_TAG,
      variables: {
        hex_code: hexCode,
        name
      }
    });
  }

  getTags() {
    return this.apollo.query({
      query: GET_TAGS,
      fetchPolicy: 'no-cache'
    })
  }

  deleteTag(id: number) {
    return this.apollo.mutate({
      mutation: DELETE_TAG,
      variables: {
        id
      }
    })
  }

  editTag(id: number, newName: string, newHexCode: string) {
    return this.apollo.mutate({
      mutation: UPDATE_TAG,
      variables: {
        id,
        hex_code: newHexCode,
        name: newName,
      }
    });
  }

  addTagsToParticipations(participationIds: number[], tagIds: number[]) {
    const output: Array<{ participation_id: number; tag_id: number }> = [];

    for (let pIndex = 0; pIndex < participationIds.length; pIndex++) {
      for (let tIndex = 0; tIndex < tagIds.length; tIndex++) {
        const combination = {
          participation_id: participationIds[pIndex],
          tag_id: tagIds[tIndex]
        };
        output.push(combination);
      }
    }

    console.log(output)

    return this.apollo.mutate({
      mutation: INSERT_TAG_PARTICIPATION_RELATIONS,
      variables: {
        objects: output
      }
    })
  }

  removeTagFromParticipation(participationId: number, tagId: number) {
    return this.apollo.mutate({
      mutation: REMOVE_TAG_FROM_PARTICIPATION,
      variables: {
        participation_id: participationId,
        tag_id: tagId
      }
    })
  }

}
