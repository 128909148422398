import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DeveloperSettingsComponent } from './developer-settings.component';



@NgModule({
  declarations: [DeveloperSettingsComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [DeveloperSettingsComponent]
})
export class DeveloperSettingsModule { }
