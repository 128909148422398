<!-- <pre>
      {{content.primary  | json}}
</pre> -->

<div class="container mx-auto">
    <div class="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto prose">
      <div *ngIf="content.primary.eyebrow_headline" class="text-center" [innerHTML]="content.primary.eyebrow_headline | richtextToHTML"></div>
      <div *ngIf="content.primary.eyebrow_description" class="text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto" [innerHTML]="content.primary.eyebrow_description | richtextToHTML"></div>
    </div>
    <div class="xl:py-16 lg:py-16 md:py-16 sm:py-16 px-15 flex flex-wrap gap-0 lg:gap-5 justify-center">
      <ng-container *ngFor="let logo of content.fields" >
        <!-- {{ logo  | json }} -->
        <a class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5  flex justify-center xl:pb-10 pb-16 first:pl-0 items-center" *ngIf="logo.logo && logo.logo.url"  target="_blank" href="{{logo.link?.url}}" >
          <img logoResize [src]="logo.logo.url" [alt]="logo.logo?.alt ? logo.logo?.alt : 'partner-logo'" role="img"/>
        </a>
      </ng-container>
    </div>
</div>
