import { Component, Input, OnInit } from '@angular/core';
import { UploadTask } from '@angular/fire/storage';
import { FileMinFields } from '@ekipa/shared';
import { DropzoneStoreService } from '../../services/dropzone-store.service';
import { FileService } from '../../services/file.service';



@Component({
  selector: 'ekipa-drop-zone-entry',
  templateUrl: './drop-zone-entry.component.html'
})
export class DropZoneEntryComponent implements OnInit {
  @Input() uploadTask?: {
    task: UploadTask,
    index: number,
    file: File
  }
  @Input() file?: FileMinFields;
  @Input() isDeletable = true;
  uploadProgress = 0;
  isUploading = false;


  constructor(private dropzoneStoreService: DropzoneStoreService, private fileService: FileService) { }

  ngOnInit(): void {
    if (this.uploadTask) {
      this.uploadTask.task.on(
        'state_changed',
        (snapshot) => {
          this.isUploading = true;
          this.uploadProgress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        },
        null,
        () => {
          this.isUploading = false;
          this.dropzoneStoreService.remove(this.uploadTask.index);
        }
      )
    }
  }

  onCancelUpload() {
    this.uploadTask.task.cancel();
    this.dropzoneStoreService.remove(this.uploadTask.index)
  }

  onDeleteByUuid() {
    if (this.file) {
      this.fileService.removeByUuid(this.file.id).subscribe()
    }
  }

}
