<div class="flex flex-col items-center m-4 text-gray-600"  *ngIf="profile$ | async as profile">
  <ekipa-avatar [size]="'md'" [name]="profile.display_name" [profileId]="profile.id" [src]="profile.photo_url" [showTooltip]="false"></ekipa-avatar>
  <h3 class="mt-2 mb-1 text-xl font-medium text-gray-900">{{profile.display_name}}</h3>
  <span class="text-sm text-gray-500 dark:text-gray-400 mb-4">Joined {{profile.created_at | date}}</span>

  <div class="flex mb-2" *ngIf="profile.data.country">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
    </svg>
    {{profile.data.country}}
  </div>
  <div class="flex mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
    </svg>
    {{profile.type === 'startup' ? 'Startup' : 'Individual innovator'}} {{profile.data.innovator_type ? '('+profile.data.innovator_type+')' : null}}
  </div>
  <div class="flex mb-2" *ngIf="profile.data.employer">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
    {{profile.data.employer}}
  </div>
  <div class="flex mb-2" *ngIf="profile.data.linkedin">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
    </svg>
    <a class="hover:underline text-primary" [href]="profile.data.linkedin" target="_blank">
      {{profile.data.linkedin}}
    </a>
  </div>
  <div class="flex mb-2" *ngIf="profile.data.university">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path d="M12 14l9-5-9-5-9 5 9 5z" />
      <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
    </svg>
    <a>
      {{profile.data.university}}
    </a>
  </div>
  <div class="flex flex-col mb-2 items-center" *ngIf="profile.data.faculties as faculties">
    <div class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-gray-700 border border-gray-300 " *ngFor="let faculty of faculties">
      <div class="text-xs font-normal leading-none max-w-full flex-initial">{{faculty}}</div>
    </div>
  </div>

  <div class="flex flex-col mb-2 items-center mt-4" *ngIf="profile.data.skills?.length">
    <!-- {{profile.data.skills | json}} -->
    <span class="flex justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
      </svg> Skills
    </span>
    <div class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-gray-700 border border-gray-300 " *ngFor="let skill of profile.data.skills">
      <div class="text-xs font-normal leading-none max-w-full flex-initial">{{skill}}</div>
    </div>
  </div>
</div>
