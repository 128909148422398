<!-- <pre>
  {{ content | json}}
</pre> -->
<div class="container mx-auto">
  <div class="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
    <div  *ngIf="content.primary.eyebrow_headline" class="text-center text-xs font-semibold uppercase text-primary">{{content.primary.eyebrow_headline[0].text}}</div>
    <div *ngIf="content.primary.title" class="text-center text-2xl font-bold text-gray-700">{{content.primary.title[0].text}}</div>
    <div  *ngIf="content.primary.description" class="text-center font-normal xl:w-10/12 xl:mx-auto" [innerHTML]="content.primary.description | richtextToHTML"></div>
  </div>

  <ng-container *ngIf="content.fields as faqEntries">
    <div class="prose-wrapper">
      <div class="mb-4" *ngFor="let entry of faqEntries" >
        <ekipa-faq-entry [content]="{title: entry.title[0].text, text: entry.text}"></ekipa-faq-entry>
      </div>
    </div>
  </ng-container>
</div>


