import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

@Pipe({ name: 'timeLeft', pure: true })
export class TimeLeftPipe implements PipeTransform {
  constructor() { }

  transform(value: string): string {
    // Using parse to prevent Safari not parsing the original Date string correctly

    const date = parseISO(value);
    const deadline = new Date(value);
    deadline.setDate(deadline.getDate() + 1);
    const currentTime = new Date();
    if (deadline.getTime()) {
      if (deadline.getTime() > currentTime.getTime()) {
        const timeLeft = formatDistanceToNow(deadline, {
          locale: enUS
        });
        return timeLeft
          ? `${capitalizeFirstLetter(timeLeft)} left`
          : date.toLocaleDateString();
      } else {
        const options: Intl.DateTimeFormatOptions = {
          weekday: "long",
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        const endedAt = deadline.toLocaleDateString('en-US', options);
        return endedAt
          ? `Ended ${endedAt}`
          : ` ${date.toLocaleDateString()}`;
      }
    } else {
      date.toLocaleDateString();
    }
  }
}


const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
