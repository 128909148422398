import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DELETE_ACCOUNT, SEND_VERIFICATION_EMAIL } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { from, switchMap } from 'rxjs';
import { API_URL } from '../tokens/tokens';
import { AuthService } from './auth.service';

/**
 * This service is created to prevent circular dependency on Apollo for AuthService
 * When ApolloClient is initialized, AuthService is needed and should not depends on Apollo
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  authService = inject(AuthService);
  httpClient = inject(HttpClient);
  apiURL = inject(API_URL)

  constructor(private apollo: Apollo) { }

  delete() {
    return this.apollo.mutate({
      mutation: DELETE_ACCOUNT,
    });
  }

  changeEmail(newEmail: string) {
    return from(this.authService.user.getIdToken(true)).pipe(
      switchMap(
        idToken => {
          return this.httpClient.get(`${this.apiURL}account/change-email`, {
            headers: { Authorization: `Bearer ${idToken}` }
          })
        }
      )
    )
  }

  verifyEmailByUserUid(uid: string) {
    return this.apollo.mutate({
      mutation: SEND_VERIFICATION_EMAIL,
      variables: {
        userUid: uid
      }
    });
  }
}
