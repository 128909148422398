import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SwUpdatesService } from './sw-updates.service';

@NgModule({
  imports: [MatSnackBarModule],
  providers: [SwUpdatesService],
})
export class SwUpdatesModule { }
