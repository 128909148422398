import { Request } from "@ekipa/shared";

export type ProfileData = IStartupProfile | IPersonalProfile;

export type ProfileType = 'personal' | 'startup';
export type InnovatorType = 'student' | 'researcher' | 'professional';
export interface IProfile {
  id?: number;
  type: ProfileType;
  display_name: string;
  photo_url?: string;
  email?: string;
  data: any;
  participations?: any;
  invitations?: any;
  requests?: Request[];
}

export interface IStartupProfile extends IProfile {
  data: IStartupProfileData;
}

export interface IPersonalProfile extends IProfile {
  data: IPersonalProfileData;
}

export interface IStartupProfileData {
  industry: string; // required
  representant_name: string; // required
  country: string; // required
  representant_position?: string;
  website?: string;
  skills?: string[];
  founded_in?: number;
  description?: string;
  instagram?: string;
  linkedin?: string;
}
export interface IProfileTeam {
  challenge_id: string;
}
export interface IProfileRequest {
  id: number;
  team: IProfileTeam;
  status: string;
}
export class StartupData implements IStartupProfileData {
  industry: string; // required
  representant_name: string; // required
  country: string; // required
  representant_position?: string;
  website?: string;
  skills?: string[];
  founded_in?: number;
  description?: string;
  company_logo?: string;
  instagram?: string;
  linkedin?: string;


  constructor(
    industry: string,
    website: string,
    skills: string[],
    foundedIn: number,
    description: string,
    company_logo: string,
    country: string,
    instagram: string,
    linkedin: string,
    representant_name: string,
    representant_position: string

  ) {
    this.industry = industry;
    this.website = website;
    this.skills = skills;
    this.founded_in = foundedIn;
    this.description = description;
    this.company_logo = company_logo;
    this.country = country;
    this.instagram = instagram;
    this.linkedin = linkedin;
    this.representant_name = representant_name;
    this.representant_position = representant_position;
  }
}

export interface IPersonalProfileData {
  university: string;
  faculties: string[];
  skills: string[];
  linkedin: string;
  innovator_type: InnovatorType;
  xing: string;
  tel: string;
  country: string;
  employer: string;
  birth_year: number;
}


export class PersonalData implements IPersonalProfileData {
  university: string;
  faculties: string[];
  skills: string[];
  linkedin: string;
  tel: string;
  innovator_type: InnovatorType;
  xing: string;
  country: string;
  employer: string;
  birth_year: number;

  constructor(
    university: string,
    faculties: string[],
    skills: string[],
    linkedIn: string,
    tel: string,
    innovator_type: InnovatorType,
    xing: string,
    country: string,
    employer: string,
    birthYear: number
  ) {
    this.university = university;
    this.faculties = faculties;
    this.skills = skills;
    this.linkedin = linkedIn;
    this.tel = tel;
    this.innovator_type = innovator_type;
    this.xing = xing;
    this.country = country;
    this.employer = employer;
    this.birth_year = birthYear;

  }
}
