import { JsonPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'ekipa-prismic-content-slice',
  templateUrl: './prismic-content-slice.component.html',
  standalone: true,
  imports: [NgIf, JsonPipe, PipesModule]
})

export class PrismiceContentSliceComponent {
  @Input() content: any;
}
