import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LogoResizeDirective } from '../../directives/logo-resize.directive';
import { PipesModule } from '../../pipes/pipes.module';
import { LegacyChallengeCardModule } from '../challenge-card/legacy-challenge-card/challenge-card.module';
import { FaqEntryComponent } from '../faq-entry/faq-entry.component';
import { PrismicCallToActionComponent } from './prismic-call-to-action/prismic-call-to-action.component';
import { PrismicCardSliceComponent } from './prismic-card-slice/prismic-card-slice.component';
import { PrismicChallengesSliceComponent } from './prismic-challenges-slice/prismic-challenges-slice.component';
import { PrismiceContentSliceComponent } from './prismic-content-slice/prismic-content-slice.component';
import { PrismicFaqSliceComponent } from './prismic-faq-slice/prismic-faq-slice.component';
import { PrismicInteractiveSectionSliceComponent } from './prismic-interactive-section-slice/prismic-interactive-section-slice.component';
import { PrismicKeyFiguresComponent } from './prismic-key-figures/prismic-key-figures.component';
import { PrismicPartnerLogosSliceComponent } from './prismic-partner-logos-slice/prismic-partner-logos-slice.component';
import { PrismicSliceWrapperComponent } from './prismic-slice-wrapper/prismic-slice-wrapper.component';
import { PrismicTeaserComponent } from './prismic-teaser/prismic-teaser.component';



@NgModule({
  declarations: [
    PrismicSliceWrapperComponent,
    PrismicPartnerLogosSliceComponent,
    PrismicCallToActionComponent,
    PrismicFaqSliceComponent,
    PrismicTeaserComponent,
  ],
  imports: [
    CommonModule,
    PrismicKeyFiguresComponent,
    PrismicChallengesSliceComponent,
    FaqEntryComponent,
    PrismicInteractiveSectionSliceComponent,
    PrismicCardSliceComponent,
    PrismiceContentSliceComponent,
    PipesModule,
    LegacyChallengeCardModule,
    TranslocoModule,
    RouterModule,
    LogoResizeDirective
  ],
  exports: [
    PrismicSliceWrapperComponent,
    PrismicFaqSliceComponent
  ]
})
export class PrismicSliceModule { }
