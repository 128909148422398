<!-- <pre>
  <p>prismic-program-challenges works!</p>
  {{
    content | json
  }}
</pre> -->
<div [ngClass]="{
  'px-4 w-full': content.primary.horizontal_padding === undefined || content.primary.horizontal_padding === true,
  'md:w-full': content.primary.horizontal_padding === false
}" class="mx-auto px-4 flex flex-col items-center">
  <div id="programChallenges" class="mb-16 prose" [ngClass]="{
    'text-left': content.primary.text_alignment === 'left',
    'text-right': content.primary.text_alignment === 'right',
    'text-center': content.primary.text_alignment === 'center' || content.primary.text_alignment === null
  }">
    <div [innerHTML]="content.primary.challenges_title | richtextToHTML"></div>
    <div *ngIf="content.primary.challenges_subtitle" class="text-gray-600 font-normal prose"
      [innerHTML]="content.primary.challenges_subtitle | richtextToHTML | sanitizeHtml"></div>
  </div>
  <div class="flex flex-wrap justify-center flex-row gap-8 my-8" *ngIf="challenges.length">
    <ng-container *ngFor="let challenge of challenges">
      <ekipa-challenge-card [challenge]="challenge" class="w-full max-w-lg md:max-w-2xl">
      </ekipa-challenge-card>
    </ng-container>
  </div>
</div>
