<div class="flex w-full mt-2 space-x-3 max-w-md gap-2"
[ngClass]="{
'ml-auto justify-end': direction === 'outgoing'
}"
>
  <ng-container *ngIf="direction === 'incoming'">
    <ng-container *ngTemplateOutlet="avatar"></ng-container>
  </ng-container>
  <div>
    <div class="py-2 px-3 shadow bg-white"
    [ngClass]="{
      'rounded-l-lg rounded-br-lg': direction === 'outgoing',
      'rounded-r-lg rounded-bl-lg': direction === 'incoming'
    }"
    >
      <p class="text-sm">{{ message.content }}</p>
    </div>
    <span class="text-xs text-gray-500 leading-none">{{ message.created_at | distanceInWords }}</span>
  </div>
  <ng-container *ngIf="direction === 'outgoing'">
    <ng-container *ngTemplateOutlet="avatar"></ng-container>
  </ng-container>
</div>

<ng-template #avatar>
  <ekipa-avatar
  *ngIf="sender.profileId"
  [name]='sender.displayName'
  [src]="sender.photoUrl"
  [profileId]="sender.profileId"
  [size]="'sm'">
  </ekipa-avatar>
  <div *ngIf="!sender.profileId" class="rounded-full inline-flex justify-center items-center cursor-pointer hover:opacity-80 h-10 w-10">
    <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
    </svg>
  </div>
</ng-template>
