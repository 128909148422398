import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekipa-tab-group',
  templateUrl: './tab-group.component.html',
})
export class TabGroupComponent {
  @Input() isCentered = false;


}
