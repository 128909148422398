<a class="group rounded-md shadow-md h-full flex flex-col cursor-pointer max-w-xl select-none"
  routerLink="/challenges/{{challengeUid}}">
  <header class="h-[120px] rounded-t-md relative overflow-hidden">
    <img src="{{ coverImageUrl }}"
      class="absolute inset-0 object-cover rounded-t-md h-full w-full group-hover:scale-125 transition-all duration-500">
    <img src="{{ logoUrl }}" class="absolute rounded-full bottom-4 left-4 h-16 w-16 bg-white p-2 object-contain">
  </header>
  <div class="p-4">
    <h2 class="font-semibold mb-2 group-hover:underline">{{ title }}</h2>
    <div class="text-sm text-gray-700">
      <p class="line-clamp-3">{{ description }}</p>
    </div>
  </div>
  <div class="mt-auto">
    <hr />
    <div class="text-sm text-gray-500 px-4 py-2 flex justify-end items-center">
      <ng-container *ngIf="isOngoing">
        <mat-icon class="!w-4 !h-4 mr-1 text-gray-500" svgIcon="alarm"></mat-icon>
        <span class=""> {{ deadline | timeLeft }}</span>
      </ng-container>
      <ng-container *ngIf="!isOngoing">
        Qualification Ended
      </ng-container>
    </div>
  </div>
</a>