import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekipa-calendar-date',
  templateUrl: './calendar-date.component.html'
})
export class CalendarDateComponent {
  @Input() date: string | Date;


}
