import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { GET_SUBMISSIONS_BY_PARTICIPATION_ID, GET_SUBMISSIONS_BY_TEAM_ID, INSERT_SUBMISSION, ParticipationMinFields, Submission } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { ParticipationService } from './participation.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {



  readonly submissions$: Observable<Submission>;

  constructor(private apollo: Apollo, private participationService: ParticipationService) {
    this.submissions$ = this.participationService.currentParticipation$.pipe(switchMap((participation: ParticipationMinFields) => {

      const submission = this._getSubmissions(participation.id, participation.team_id).pipe(
        switchMap(({ data }) => data.submission)
      );
      return submission;
    }))
  }



  private _getSubmissions(participationId: number, teamId: number):
    Observable<FetchResult<{ submission: Submission[]; }, Record<string, any>, Record<string, any>>> {
    const query = teamId ? GET_SUBMISSIONS_BY_TEAM_ID : GET_SUBMISSIONS_BY_PARTICIPATION_ID;
    const variables = teamId ? { team_id: teamId } : { participation_id: participationId }
    return this.apollo.subscribe<{
      submission: Submission[];
    }>(
      {
        query,
        variables
      });
  }

  createSubmission(
    submission: any,
  ): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    return this.apollo.mutate({
      mutation: INSERT_SUBMISSION,
      variables: submission
    });
  }
}
