import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { firstValueFrom, map } from 'rxjs';
import { PRISMIC_GRAPHQL_URL } from '../tokens/tokens';

@Injectable({
  providedIn: 'root'
})
export class PrismicLinkService {
  prismicLink: HttpLinkHandler;
  prismicRef = '';
  constructor(
    @Inject(PRISMIC_GRAPHQL_URL) private prismicURL: string,
    private httpLink: HttpLink,
    private http: HttpClient
  ) {
  }

  loadPrismicRef(): Promise<string> {
    const ref = this.fetchPrismicRef().then((ref) => { this.prismicRef = ref; return ref; });
    return ref;
  }

  createPrismicLink() {
    const headers = new HttpHeaders({
      'prismic-ref': this.prismicRef
    });

    const prismicLink = this.httpLink.create({
      uri: this.prismicURL,
      useGETForQueries: true,
      headers,
    })
    return prismicLink;

  }


  private fetchPrismicRef(): Promise<string> {
    const prismicRepositoryName = new URL(this.prismicURL).hostname.split(".")[0];
    //https://github.dev/prismicio/prismic-client/blob/master/src/getRepositoryEndpoint.ts
    const prismicRepositoryEndpoint = `https://${prismicRepositoryName}.cdn.prismic.io/api/v2`
    const ref = firstValueFrom(this.http.get(prismicRepositoryEndpoint).pipe(
      map((response) => {
        const masterRef = (response as any).refs[0].ref;
        return masterRef;
      })
    ))
    return ref;
  }
}
