import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService as NGXCookieService } from 'ngx-cookie';
import { Observable, Observer } from 'rxjs';
import { CookieLayerComponent } from '../components/cookie-layer/cookie-layer.component';

const SettingsKey = 'cookie_settings';

export interface CookieSettingsItems { analytics: boolean; }

@Injectable({ providedIn: 'root' })
export class CookieService {
  private scripts: ScriptModel[] = [];
  public isBrowser: boolean;

  constructor(
    private cookieService: NGXCookieService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  load() {
    if (this.isBrowser) {
      if (!this._isSet()) {
        this.openCookieSettings();
      } else {
        this.loadScripts(this.getCookieSettings());
      }
    }
  }

  openCookieSettings() {
    const dialogRef = this.dialog.open<CookieLayerComponent, any, CookieSettingsItems>(CookieLayerComponent, {
      width: '600px',
      minHeight: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .subscribe(acceptedCookieCategories => {
        this.saveCookieSettings(acceptedCookieCategories);
        this.loadScripts(acceptedCookieCategories);
      });
  }

  saveCookieSettings(acceptedCookieCategories: CookieSettingsItems) {
    // Set expiry Date for Cookie 5 years form now
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 5);
    this.cookieService.putObject(SettingsKey, acceptedCookieCategories, { expires: expiryDate });
  }

  getCookieSettings(): CookieSettingsItems {
    return this.cookieService.getObject(SettingsKey) as CookieSettingsItems;
  }

  private _isSet(): boolean {
    return this.cookieService.hasKey(SettingsKey);
  }

  private loadScripts(acceptedCookieCategories: CookieSettingsItems) {
    Object.keys(acceptedCookieCategories)
      .filter((key) => acceptedCookieCategories[key])
      .forEach(key => this.loadScript({
        name: key,
        src: `/assets/scripts/${key}-27042023.js`,
        loaded: false
      }).subscribe());
  }

  private loadScript(script: ScriptModel): Observable<ScriptModel> {

    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;
        scriptElement.async = true;

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error('Couldn\'t load script ' + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}

export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
}
