import { JsonPipe, NgForOf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'ekipa-prismic-interactive-section-slice',
  templateUrl: './prismic-interactive-section-slice.component.html',
  standalone: true,
  imports: [JsonPipe, PipesModule, NgForOf]
})

export class PrismicInteractiveSectionSliceComponent implements OnInit {
  @Input() content: any;
  selectedItemIndex = 0
  constructor() { }

  ngOnInit() { }
}
