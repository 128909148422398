import { Injectable } from '@angular/core';
import { GET_MESSAGES_BY_TEAM, INSERT_MESSAGE, ITeam, MessageMinFields } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class TeamMessageService {

  constructor(
    private apollo: Apollo,
    private teamService: TeamService,
    private profileService: ProfileService
  ) { }

  readonly currentMessages$: Observable<MessageMinFields[]> =
    this.teamService.currentTeam$.pipe(
      switchMap((team: ITeam) => {
        if (team) {
          return this.apollo.subscribe({
            query: GET_MESSAGES_BY_TEAM,
            variables: {
              team_id: team.id
            }
          }).pipe(map(
            res => {
              if (res.data) {
                return (res.data as any).message
              } else {
                return res
              }
            }
          ))
        } else EMPTY;
      })
    )

  insertTeamMessage(content: string) {
    return combineLatest(
      [this.teamService.currentTeam$, this.profileService.profile$]
    ).pipe(
      switchMap(([team, profile]) => {
        return this.apollo.mutate({
          mutation: INSERT_MESSAGE,
          variables: {
            team_id: team.id,
            sender_id: profile.id,
            content
          }
        })
      }),
      take(1)
    )
  }

  insertRequestMessage(content: string, requestId: number, senderId: number) {
    return this.apollo.mutate({
      mutation: INSERT_MESSAGE,
      variables: {
        request_id: requestId,
        sender_id: senderId,
        content
      }
    })
  }
}
