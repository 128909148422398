const asText = (richTextField, separator = " ") => {
  let result = "";
  for (let i = 0; i < richTextField.length; i++) {
    if ("text" in richTextField[i]) {
      result += (result ? separator : "") + richTextField[i].text;
    }
  }
  return result;
};
export { asText };
