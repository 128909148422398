import { ProgressState } from "./progress.model";
import { UploadedFileModel } from "./upload.models";


export enum SubmissionState {
  NotStarted,
  Ongoing,
  AwaitingApproval,
  ReadyToSubmit,
  Submitted
}




export interface Submission {
  id?: number;
  team_id?: number;
  data?: UploadedFileModel[];
  updated_at: any;
}

export interface SubmissionProgressStep {
  submissionState: SubmissionState;
  title: string,
  description: string,
  state: ProgressState
}