import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { NEVER, Observable, Subject, concat, interval } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 6 hours.
 * 3. Whenever an update is available, it activates the update.
 *
 * @property
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@Injectable()
export class SwUpdatesService implements OnDestroy {
  private checkInterval = 1000 * 60 * 60 * 6; // 6 hours
  private onDestroy = new Subject<void>();
  updateActivated: Observable<string>;

  constructor(
    appRef: ApplicationRef,
    private swu: SwUpdate,
    private snackBar: MatSnackBar,
  ) {
    if (!swu.isEnabled) {
      this.updateActivated = NEVER.pipe(takeUntil(this.onDestroy));
      return;
    }

    // Periodically check for updates (after the app is stabilized).
    const appIsStable = appRef.isStable.pipe(first(v => v));
    concat(appIsStable, interval(this.checkInterval))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.swu.checkForUpdate());

    // Activate available updates.
    this.swu.versionUpdates.pipe(takeUntil(this.onDestroy)).subscribe((e: VersionEvent) => {
      if (e.type !== 'NO_NEW_VERSION_DETECTED') {
        this.swu.activateUpdate()
          .then(() => {
            const snack = this.snackBar.open(
              'New version of ekipa is available',
              'Reload',
              {
                panelClass: ['ekipa-snack-bar']
              }
            );

            snack.onAction().subscribe(() => {
              document.location.reload();
            });
          })
      }
    }
    );

    // Notify about activated updates.
    // this.updateActivated = this.swu.activated.pipe(
    //   map(evt => evt.current.hash),
    //   takeUntil(this.onDestroy)
    // );
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
