<div>
  <img class="h-12 block cursor-pointer" alt="ekipa Logo" src="/assets/images/logo.png" routerLink="/" />
  <h1 class="text-lg font-bold my-4">Password reset</h1>
</div>
  <div [hidden]="resetClicked">
    <div class="text-left my-4 text-gray-500 sm:font-normal tracking-wide">
      {{"actions.resetText" | transloco }}
    </div>
    <form (submit)="onSubmit()">
      <div class="control">
        <input [formControl]="email"
          class="w-full"
          type="email" placeholder="Your E-mail" />
        <p *ngIf="email.invalid && email.touched" class="flex mt-1">
          {{ "form.invalidEmail" | transloco }}
        </p>
      </div>

      <div *ngIf="isEmptyError">
        *{{ "form.emptyInput" | transloco }}
      </div>
      <br />

      <button type="submit" class="btn btn-primary">{{ "actions.reset" | transloco }}</button>
    </form>



  </div>

  <div *ngIf="resetClicked" class="mt-8 text-lg">
    <p class="text-gray-500 sm:font-normal tracking-wide border-2 border-dashed rounded-md p-4">{{ "resetPassword.success.description" | transloco }}</p>
    <button type="submit" class="btn btn-primary mt-4" routerLink="/login">To login</button>
  </div>
