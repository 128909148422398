import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';

@Component({
  selector: 'ekipa-prismic-card-slice',
  templateUrl: './prismic-card-slice.component.html',
  standalone: true,
  imports: [NgForOf, JsonPipe, PipesModule, NgIf]
})

export class PrismicCardSliceComponent implements OnInit {
  @Input() content: any;
  constructor() { }

  ngOnInit() { }
}
