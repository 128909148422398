import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID, Pipe, PipeTransform } from '@angular/core';
import * as PrismicHelper from '@prismicio/helpers';

/*
 * get rich text from prismic content
*/
@Pipe({ name: 'richtextToHTML' })
export class RichtextToHTMLPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: string) { }


  transform(value: any, textColor?: string): string {
    if (value) {
      const htmlString = PrismicHelper.asHTML(value);
      if (!textColor) {
        return htmlString;
      } else {
        if (isPlatformBrowser(this.platformId)) {
          // if text color is set in Prismic, add color attribute to the html string
          const parser = new DOMParser();
          const doc: Document = parser.parseFromString(htmlString, 'text/html');
          doc.querySelectorAll('p, strong').forEach((elem) => {
            elem.setAttribute('style', `color: ${textColor};`);
          })
          return doc.body.innerHTML;
        } else {
          return htmlString;
        }
      }
    }
  }
}
