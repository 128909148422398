<div class="p-8 block overflow-auto w-full h-full leading-6 text-gray-700">
  <h1 class="mb-4 text-2xl font-light ">Your privacy settings</h1>
  <p class="mb-8 text-md font-light break-words">
    ekipa uses cookies and the browser localstorage to give its readers the best website experience
    possible. Cookies from third party services are also sometimes
    set. You can find more information in our
    <a href="https://ekipa.de/datenschutz/" class="font-semibold">Privacy declaration</a>
  </p>
  <div class="is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-center"
    [ngClass]="{ 'mb-4': !isCollapsed }">
    <button type="button" class="inline-flex relative
        justify-center items-center
        mt-0 mr-4 mb-2 h-10
        font-light text-lg leading-normal
        text-center text-black align-top
        focus:outline-none
        bg-white border
        border-transparent
        border-solid shadow-none
        appearance-none cursor-pointer
        select-none whitespace-no-wrap
        focus:border-transparent
        focus:text-black
        p-2
        rounded-md
        hover:bg-gray-100
        hover:border-transparent hover:text-black" (click)="toggleCollapse()">
      Settings<mat-icon svgIcon="keyboard_arrow_down" *ngIf="isCollapsed"></mat-icon>
      <mat-icon svgIcon="keyboard_arrow_up" *ngIf="!isCollapsed"></mat-icon>
    </button>
    <button type="button" class="btn btn-primary" *ngIf="isCollapsed" (click)="acceptAll()">
      Accept all
    </button>
  </div>
  <div class="columns" *ngIf="!isCollapsed">
    <div class="column">

      <div class="mb-5 field">
        <mat-checkbox class="font-semibold" checked="checked" disabled>Necessary</mat-checkbox>
        <div class="">
          <!-- <cdk-accordion class="p-8 m-8">
            <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0"
              [attr.id]="'accordion-header'+1" [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body'">
              <div class="text-sm font-semibold flex items-center justify-between mx-8"
                (click)="accordionItem.toggle()">
                <span>Google reCAPTCHA</span>
                <div>
                  <mat-icon *ngIf="accordionItem.expanded" svgIcon="keyboard_arrow_up"></mat-icon>
                  <mat-icon *ngIf="!accordionItem.expanded" svgIcon="keyboard_arrow_down"></mat-icon>
                </div>
              </div>
              <div class="text-sm text-gray-600 mx-8" role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body'"
                [attr.aria-labelledby]="'accordion-header'">
                We use "Google reCAPTCHA" (hereinafter "reCAPTCHA") on our websites as part of the voting feature. The
                provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google").
                <br />
                The purpose of reCAPTCHA is to verify whether data entry on our websites (e.g. in a contact form) is
                made by a human or by an automated program. For this purpose, reCAPTCHA analyzes the behavior of the
                website visitor based on various characteristics. This analysis starts automatically as soon as the
                website visitor enters the website. For the analysis, reCAPTCHA evaluates various information (e.g. IP
                address, time spent by the website visitor on the website or mouse movements made by the user). The data
                collected during the analysis is forwarded to Google.
                <br />
                The reCAPTCHA analyses run entirely in the background. Website visitors are not made aware that an
                analysis is taking place.
                <br />
                The data processing is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a legitimate
                interest in protecting its web offers from abusive automated spying and from SPAM.
                <br />
                For more information about Google reCAPTCHA and Google's privacy policy, please see the following:
                <a
                  href="https://www.google.com/intl/de/policies/privacy/">https://www.google.com/intl/de/policies/privacy/</a>
                <br />
                <a href="https://www.google.com/recaptcha/about/">https://www.google.com/recaptcha/about/</a>
              </div>
            </cdk-accordion-item>
          </cdk-accordion> -->
          <cdk-accordion class="p-8 m-8">
            <cdk-accordion-item #accordionItem2="cdkAccordionItem" class="example-accordion-item" role="button"
              tabindex="1" [attr.id]="'accordion-header'+2" [attr.aria-expanded]="accordionItem2.expanded"
              [attr.aria-controls]="'accordion-body'">
              <div class="text-sm flex items-center justify-between mx-8" (click)="accordionItem2.toggle()">
                <span>Google Firebase (concerning logged-in user only)</span>
                <div>
                  <mat-icon *ngIf="accordionItem2.expanded" svgIcon="keyboard_arrow_up"></mat-icon>
                  <mat-icon *ngIf="!accordionItem2.expanded" svgIcon="keyboard_arrow_down"></mat-icon>
                </div>
              </div>
              <div class="text-sm text-gray-600 mx-8" role="region"
                [style.display]="accordionItem2.expanded ? '' : 'none'" [attr.id]="'accordion-body'"
                [attr.aria-labelledby]="'accordion-header'">
                Our website uses technologies from Google Firebase. Google Firebase is a service of Google Ireland
                Limited, Gordon House, Barrow Street, Dublin 4, Ireland. Google Firebase is a development platform and
                offers various services. An overview of services offered by Google Firebase can be found at:
                https://firebase.google.com/terms/.
                <br />
                In part, the Google Firebase services use so-called "Instance IDs". "Instance IDs" are uniquely assigned
                identifiers that are provided with a time stamp and enable the linking of different events or processes
                in connection with the app. This data is used to analyze and optimize user behavior, such as evaluating
                crash reports. According to Google, Instance IDs do not process any personally identifiable data.
                <br />
                Further information on the "Instance IDs" used and on the management of the data concerned can be found
                at:
                <a
                  href="https://firebase.google.com/support/privacy/manage-iids">https://firebase.google.com/support/privacy/manage-iids</a>
              </div>
            </cdk-accordion-item>
          </cdk-accordion>

        </div>
      </div>
      <div class="field">
        <mat-checkbox class="font-semibold" id="analytics" name="analytics" [checked]="isAnalyticsAllowed"
          (change)="isAnalyticsAllowed = !isAnalyticsAllowed">Analytical</mat-checkbox>
        <p class="mt-2 ml-4 font-light">
          We analyse website-data to optimise your experience and evaluate the best marketing channels, to get more
          people interested in innovation.
        </p>
      </div>
    </div>



  </div>

  <div *ngIf="!isCollapsed" class="mt-8 is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-center"
    [ngClass]="{ 'is-justify-content-space-between': !isCollapsed }">
    <button type="button" class="inline-flex relative p-5 flex-grow
      justify-center items-center mr-2 mt-0 mb-2
      h-10 text-base text-center
      text-gray-800 align-top bg-white
      border border-gray-300
      shadow-none appearance-none
      cursor-pointer select-none
      whitespace-no-wrap
      focus:border-blue-400
      focus:text-gray-800
      hover:border-gray-500
      hover:text-gray-800" (click)="saveSelection()" style="border-radius: 20px;">
      Save selection
    </button>
    <button type="button" class="inline-flex relative p-5 flex-grow
      justify-center items-center mr-2 mt-0 mb-2
      h-10 text-base text-center
      text-gray-800 align-top bg-white
      border border-gray-300 border-solid
      shadow-none appearance-none
      cursor-pointer select-none
      whitespace-no-wrap
      focus:border-blue-400
      focus:text-gray-800
      hover:border-gray-500
      hover:text-gray-800" (click)="rejectAll()" style="border-radius: 20px;">
      Reject unnecessary cookies
    </button>
    <button type="button" class="btn btn-primary" (click)="acceptAll()">
      Accept all
    </button>
  </div>
</div>
