<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div class="flex justify-end" mat-dialog-actions>
  <button class="btn btn-background mr-2" (click)="onDismiss()">{{ "button.back" | transloco }}</button>
  <button class="btn btn-primary" (click)="onConfirm()">{{ "button.okay" | transloco }}</button>
</div>
