import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { AuthService, PRISMIC_GRAPHQL_URL, ProfileService } from '@ekipa/common-ui';
import { ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE, PRISMIC_GRAPHQL_URL_COOKIE } from '@ekipa/shared';
import { CookieService as NGXCookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeveloperService } from '../../services/developer.service';


@Component({
  selector: 'ekipa-developer-settings',
  template: `
  <div class="mb-4 mx-4">
    <button class="btn btn-shadow bg-white" (click)='showSettings = !showSettings'>{{ showSettings ? '❌ Close' : '🛠️ Demo settings'}}</button>
    <button class="btn btn-shadow bg-white border border-red-500 animate-roll-in-left" (click)='onReload()' *ngIf="showReloadButton">🔃 Reload</button>
  </div>
  <div class="flex flex-col  rounded-md shadow-lg px-8 m-4 bg-white" *ngIf="showSettings">
    <h2 class="text-xl title-font font-medium text-gray-900 mb-8 mt-4">🛠️ Demo settings </h2>
    <label class="border-b-2 border-gray-100 pb-4 mb-4">
      <input
        class="text-primary"
        type="checkbox"
        [(ngModel)]="allowUnverifiedSignIn"
        (change)="updateAllowVerificationCookie()"
      />
        <span class="ml-2 align-top inline-block">
          Unverified Sign In
          <div class="text-gray-400">
            Used in development create account and login without verifying. Not allowed in production.
          </div>
        </span>
    </label>
    <label class="border-b-2 border-gray-100 pb-4 mb-4">
      <span class="text-gray-700">Select Prismic CMS instance</span>
      <select class="form-select block w-full mt-1"
      (ngModelChange)="updatePrismicInsance($event)"
      [ngModel]="selectedPrismicInstance">
        <option  value="https://ekipa-integrationfirebaseappcom.prismic.io/graphql">Demo</option>
        <option   value="https://ekipa.prismic.io/graphql">Production</option>
      </select>
    </label>
    <label class="border-b-2 border-gray-100 pb-4 mb-4">
      <button class="text-primary underline mr-4" (click)="onWipe()">Wipe</button>
      <button class="text-primary underline" (click)="onResetDB()">Repopulate</button>
      <div class="text-gray-400">
        Wipe the test database or repopulate with mock database, all manually created profiles or other data will be wipped out.
      </div>
    </label>
    <label class="border-b-2 border-gray-100 pb-4 mb-4 text-sm">
      <div class="text-md title-font font-medium mb-2">Auth data </div>
      <pre class="overflow-y-scroll h-80 bg-gray-50">{{authService.authState$ | async | json }}</pre>
    </label>
    <label class="border-b-2 border-gray-100 pb-4 mb-4 text-sm">
      <div class="text-md title-font font-medium mb-2">Profile data </div>
      <pre class="overflow-y-scroll h-80 bg-gray-50">{{profileService.profile$ | async | json }}</pre>
    </label>
    <label class="border-b-2 border-gray-100 pb-4 mb-4 text-sm" *ngIf="claims$ | async | json as claims">
      <div class="text-md title-font font-medium mb-2">Token Claims </div>
      <pre class="overflow-y-scroll h-80 bg-gray-50">{{claims}}</pre>
    </label>
  </div>
  `,
  styles: [`
  :host {
    position: fixed;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    max-width: 830px;
    max-height: 100vh;
    z-index: 999;
    border-radius: 4px;
  }
  `]
})
export class DeveloperSettingsComponent implements OnInit {
  showReloadButton = false;
  showSettings = false;
  allowUnverifiedSignIn = false;
  selectedPrismicInstance!: string;
  claims$: Observable<any>;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      // Clicked outside
      this.showSettings = false;
    }
  }




  constructor(
    private eRef: ElementRef,
    private cookieServie: NGXCookieService,
    private developerService: DeveloperService,
    public authService: AuthService,
    public profileService: ProfileService,
    @Inject(PRISMIC_GRAPHQL_URL) private prismicUrlFromEnv: string,
  ) { }

  updateAllowVerificationCookie() {
    if (this.allowUnverifiedSignIn) {
      this.cookieServie.put(ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE, '')
    } else {
      this.cookieServie.remove(ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE)
    }
    this.showReloadButton = true;
  }

  updatePrismicInsance(event: string) {
    this.selectedPrismicInstance = event;
    this.cookieServie.put(PRISMIC_GRAPHQL_URL_COOKIE, event)
    this.showReloadButton = true;
  }

  onReload() {
    if (window) {
      window.location.reload();
    }
  }

  onWipe() {
    this.developerService.wipeDB().subscribe();
    this.authService.signOut();
  }

  onResetDB() {
    this.developerService.resetDB().subscribe(a => console.log(a));
    this.authService.signOut();
  }


  ngOnInit() {
    this.allowUnverifiedSignIn = this.cookieServie.get(ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE) !== undefined;
    this.selectedPrismicInstance = this.cookieServie.get(PRISMIC_GRAPHQL_URL_COOKIE) || this.prismicUrlFromEnv;
    this.claims$ = this.authService.authState$.pipe(map(async (user) => {
      if (user) {
        return (await (await user.getIdTokenResult()).claims)
      }
    }))
  }

}
