import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'ekipa-reset-password',
  templateUrl: './reset-password.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatDialogModule
  ],
})
export class ResetPasswordComponent {
  resetClicked = false;
  isEmptyError = false;
  email: FormControl<string>;
  constructor(private authService: AuthService) {

    this.email = new FormControl<string>('', [
      Validators.required,
      Validators.email
    ]);
  }



  onSubmit(): void {
    if (this.email.valid) {
      this.authService.sendPasswordResetEmail(this.email.value)
      this.resetClicked = true;
    } else {
      if (!this.email.value) {
        this.isEmptyError = true;
      }
    }
  }

}
