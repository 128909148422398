import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SlugifyPipe } from '../pipes/slugify.pipe';


export interface TocItem {
    content: string;
    href: string;
    isSecondary?: boolean;
    level: string;
    title: string;
    slug: string;
}

@Injectable()
export class TocService {
    tocList = new ReplaySubject<TocItem[]>(1);

    constructor(
        @Inject(DOCUMENT) private document: any,
        private slugifyPipe: SlugifyPipe) { }

    genToc(content, docId = ''): void {
        if (!content || content.length === 0) {
            this.tocList.next([]);
            return;
        }

        const headings = this.findTocHeadings(content);
        const tocList = headings.map(heading => ({
            content: heading.text,
            level: '2',
            title: heading.text,
            slug: this.slugifyPipe.transform(heading.text),
            href: `${docId}#` + this.slugifyPipe.transform(heading.text),
        }));

        this.tocList.next(tocList);
    }

    reset(): void {
        this.tocList.next([]);
    }

    private findTocHeadings(content): any[] {
        const headings = ['heading1', 'heading2', 'heading3', 'heading4', 'heading5', 'heading6'];
        return Array.prototype.filter.call(content, (x) => {
            return x.type.includes('heading');
        });
    }
}
