import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';


export interface PrismicKeyFigureSliceEntry {
  figure: string,
  figure_title: {
    type: string,
    text: any[],
    spans: any[],
  }[]
}



@Component({
  selector: 'ekipa-prismic-key-figures',
  templateUrl: './prismic-key-figures.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class PrismicKeyFiguresComponent {
  @Input() content: { fields: PrismicKeyFigureSliceEntry[] };
}
