<div class="overflow-hidden group h-full" [ngClass]="
{
  'opacity-50 cursor-not-allowed': isDisabled,
  'skeuomorphic': isActive
}
">
  <a class="">
    <span
      class="absolute  top-auto left-0 w-1 h-full  md:bottom-0 md:h-1 md:w-full group-hover:visible disabled:group-hover:invisible"
      [ngClass]="{
      'bg-gray-200 invisible' : !isActive,
      'bg-primary visible': isActive
    }"></span>
    <span class="flex items-start py-2 md:py-6 pl-2 lg:pl-6">
      <span class="shrink-0">
        <span *ngIf="isDone" class="rounded-full justify-center items-center w-8 h-8 flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
              clip-rule="evenodd"></path>
          </svg>
        </span>
        <span *ngIf="!isDone && !step.symbol"
          class="text-gray-400 rounded-full justify-center items-center flex border-2 border-gray-400 h-10 w-10">{{index
          + 1}}</span>
        <span *ngIf="!isDone && step.symbol"
          class="text-gray-400 rounded-full justify-center items-center flex h-10 w-10 text-xl">{{step.symbol}}</span>
      </span>
      <span class="flex flex-col min-w-0 ml-4">
        <span class="font-medium text-sm">
          {{index+1}}. {{step.title}}
        </span>
        <span class="text-gray-400 text-sm">
          {{step.description}}
        </span>
      </span>
    </span>
    <div class="hidden md:block w-3 top-0 left-0 inset-0 absolute" *ngIf="index !== 0">
      <svg viewBox="0 0 12 82" class="text-gray-200 h-full" fill="none" preserveAspectRatio="none">
        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke"></path>
      </svg>
    </div>
  </a>
</div>
