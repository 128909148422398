import { Component } from '@angular/core';

@Component({
  selector: 'ekipa-clickable-list-item',
  template: `
    <div class="pr-4 mb-4 cursor-pointer">
      <div class="w-full h-full p-5 my-4 bg-white rounded-lg md:my-2 border-2 border-transparent hover:border-primary transition">
        <div class="flex justify-between px-2">
          <ng-content select="[header]"></ng-content>
          <ng-content select="[icon]"></ng-content>
        </div>
        <div class="p-2 leading-4">
            <ng-content select="[description]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [`
  :host {
    @apply w-full;
  }
  `]
})
export class ClickableListItemComponent {
}
