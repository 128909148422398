import { NgModule } from '@angular/core';
import { RichtextToHTMLPipe } from './richtext-to-html.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { TimeLeftPipe } from './time-left.pipe';
import { DistanceInWordsPipe } from './distance-in-words.pipe';

@NgModule({
  declarations: [SanitizeHtmlPipe, SlugifyPipe, RichtextToHTMLPipe, TimeLeftPipe, DistanceInWordsPipe],
  exports: [SanitizeHtmlPipe, SlugifyPipe, RichtextToHTMLPipe, TimeLeftPipe, DistanceInWordsPipe],
  providers: [SlugifyPipe]
})
export class PipesModule { }



