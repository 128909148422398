<div class="flex flex-row items-center">
    <div class="w-full relative m-2">
      <input
      type="text"
      [(ngModel)]="message"
      class="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
      placeholder="Type your message...."
      (keyup.enter)="onSend()"
      >
      <button class="text-white absolute right-2.5 bottom-2.5 bg-primary hover:bg-primary/80 font-medium rounded-xl text-sm px-4 py-2" (click)="onSend()">
        <svg class="w-5 h-5 transform rotate-90 -mr-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
        </svg>
      </button>
    </div>
</div>
