import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropzoneStoreService {
  entries = [];

  constructor() { }

  remove(index: number) {
    this.entries.splice(index, 1);
    console.log(this.entries)
  }
}
