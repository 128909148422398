import { Injectable } from '@angular/core';
import { SUBSCRIBE_TEAM_PARTICIPANTS, UPSERT_TEAM_PARTICIPANTS } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamParticipantsInfoService {
  constructor(
    private apollo: Apollo,
  ) { }

  upsert(teamId: number, data: any) {
    return this.apollo.mutate({
      mutation: UPSERT_TEAM_PARTICIPANTS,
      variables: {
        team_id: teamId,
        data
      }
    })
  }

  watch(teamId: number) {
    return this.apollo.subscribe({
      query: SUBSCRIBE_TEAM_PARTICIPANTS,
      variables: {
        team_id: teamId
      }
    }).pipe(
      map(({ errors, data }) => {
        if (errors) return errors;
        return (data as any).team_participants[0]?.data
      }))
  }

}
