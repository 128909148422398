import { Component, Inject, OnInit } from '@angular/core';
import { GET_PROFILE_BY_ID } from '@ekipa/shared';
import { TippyInstance, TIPPY_REF } from '@ngneat/helipopper';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'ekipa-profile-info-tooltip',
  templateUrl: './profile-info-tooltip.component.html'
})

export class ProfileInfoTooltipComponent implements OnInit {
  profile$: Observable<any>;
  instance: TippyInstance;
  profileId: number;

  constructor(private apollo: Apollo, @Inject(TIPPY_REF) tippy: TippyInstance) {
    this.profileId = tippy.data.profileId;
  }


  ngOnInit() {
    this.profile$ = this.apollo.query({ query: GET_PROFILE_BY_ID, variables: { id: this.profileId } }).pipe(map(
      ({ data, loading, error }) => { if (data) return (data as any).profile[0] }
    ))
  }

}
