import { Injectable } from '@angular/core';
import { EkipaUploadTask, ITeam } from '@ekipa/shared';
import { Subject } from 'rxjs';
import { UploadedFileModel } from '../models/upload.models';



@Injectable({
  providedIn: 'root'
})
export class SubmissionStoreService {

  submissions: UploadedFileModel[] = [];
  isSubmissionChanged = false;
  team: ITeam;
  currentTasks: EkipaUploadTask[] = [];
  submissions$ = new Subject<UploadedFileModel[]>();


  onUploadTaskStarted(task: EkipaUploadTask): void {
    // console.log('onUpload: ' + task.createdBy);
    this.currentTasks.push(task);
  }

  onUploadTaskFinished(downloadURL: string, fileId: string): void {
    const finishedTask: EkipaUploadTask = this.currentTasks.find(task => task.fileId === fileId);
    this.currentTasks = this.currentTasks.filter(task => task.fileId !== fileId);

    const submission: UploadedFileModel = {
      id: fileId,
      uploadedBy: {
        userUID: finishedTask.createdBy.userUID,
        username: finishedTask.createdBy.username
      },
      downloadURL,
      title: finishedTask.file.name
    };

    // console.log('submission: \n\n' + JSON.stringify(submission));

    this.submissions.push(submission);
    console.log('submission: \n\n\n', this.submissions)
    this.submissions$.next(this.submissions);
    this.isSubmissionChanged = true;
  }

  deleteSubmission(fileID: string): void {
    const index: number = this.submissions.indexOf(
      this.submissions.filter(item => item.id === fileID)[0]
    );
    const newSubmissions = this.submissions.splice(index, 1);
    this.submissions$.next(newSubmissions);
  }
}
